import React from "react";
import { get } from "lodash";
import moment from "moment-timezone";
import Link from "redux-first-router-link";

import { Org } from "lib/models/Org";
import { Message } from "lib/models/Message";

import { renderModifiedCell } from "utils";
import config from "../../../lib/config";

// not currently used
// import ErrorIcon from '@mui/icons-material/Error'
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

import { PlatformLogo } from "../../Components/BlumiraAgent/PlatformLogos/platforms";

export const pricingUrl = "https://youtu.be/_caMQpiwiaU";
export const blumiraAgentSetupUrl = "https://youtu.be/_caMQpiwiaU";

export const windowsBaseInstallScriptCommand = `[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12; . { iwr -useb ${config.agentDownloadUrl}.blumira.com/agent/poshim.ps1 }| iex; Blumira-Installer -Configuration ${config.agentDownloadUrl}.blumira.com/agent/poshim_config.json -Agent`;

export const linuxBaseInstallScriptCommand = `curl -sS '${config.agentDownloadUrl}.blumira.com/blumira_linux_agent.sh' | sudo bash -s`;
export const macBaseInstallScriptCommand = `curl -sS '${config.agentDownloadUrl}.blumira.com/blumira_macos_agent.sh' | sudo bash -s -- -i`;

export const getInstallationScript = (keyValue, platform) => {
  if (platform === "mac") {
    return macBaseInstallScriptCommand + " " + keyValue;
  } else if (platform === "linux") {
    return linuxBaseInstallScriptCommand + " " + keyValue;
  } else {
    return windowsBaseInstallScriptCommand + " " + keyValue;
  }
};

export const agentSources = ["windows", "http_access", "agent_os"];

const renderPlatformIcon = (column, model) => {
  if (model[column.field] === "Windows") {
    return <PlatformLogo platformType={"windows"} />;
  } else if (model[column.field] === "macOS") {
    return <PlatformLogo platformType={"mac"} />;
  } else if (model[column.field] === "Linux") {
    return <PlatformLogo platformType={"linux"} />;
  }
};

// devicesTableColumns are searchable the rest of deviceDetails
// is for displaying in the device's detail dialog view

export const deviceFields = [
  {
    title: "Device hostname",
    field: "hostname",
    searchable: true,
  },
  {
    title: "Agent status",
    field: "alive",
    searchable: true,
    renderValue: (column, model) => {
      // if alive is set to a date the device is online
      const textToDisplay = model[column.field] ? "Online" : "Offline";

      if (!model[column.field]) {
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <NotInterestedIcon style={{ color: "red", marginRight: 5 }} />{" "}
            {textToDisplay}
          </span>
        );
      }

      return textToDisplay;
    },
  },
  {
    title: "Platform",
    field: "platformName",
    searchable: true,
    icon: (column, model) => renderPlatformIcon(column, model),
    renderValue: (column, model) => model[column.field],
  },
  {
    title: "Logs",
    renderDetailText: (column, model) => {
      return (
        <Link target={"_blank"} to={getPageActionToDispatch(model)}>
          View device logs <OpenInNewIcon />
        </Link>
      );
    },
  },
  {
    title: "Unresolved findings",
    renderDetailText: (column, model) => {
      const deviceNameForFilter = get(model, ["hostname"], "");

      return (
        <Link
          target={"_blank"}
          to={{
            type: "PAGE",
            payload: {
              orgId: model.orgId,
              toplevel: "reporting",
              secondlevel: "findings",
            },
            query: {
              status: 0,
              deviceName: deviceNameForFilter,
            },
          }}
        >
          View unresolved findings <OpenInNewIcon />
        </Link>
      );
    },
    // searchable: true,
    // renderValue: (column, model) => {
    //     // if not 0
    //     if(model[column.field]){
    //         return (
    //             <span style={{ display: 'flex', alignItems: 'center' }}>
    //                 <ErrorIcon style={{ color: 'red', marginRight: 5 }}/> {model[column.field]}
    //             </span>
    //         )
    //     }

    //     return model[column.field]
    // }
  },
  {
    title: "Internal IP",
    field: "internalIP", // this value is requested from LC and not stored
  },
  {
    title: "External IP",
    field: "externalIP", // this value is requested from LC and not stored
  },
  {
    title: "Mac address",
    field: "macAddr", // this value is requested from LC and not stored
  },
  {
    title: "First seen",
    field: "firstSeen", // this value is requested from LC and not stored
  },
  {
    title: "Host isolation",
    field: "isIsolated",
    searchable: true,
    hideDetails: true,
    renderValue: (column, model) => {
      const isIsolated = model.isIsolated;
      const isolationRequested = model.isolationRequested;

      if (isIsolated && isolationRequested) {
        return "Isolated";
      } else if (isIsolated && !isolationRequested) {
        return "Pending Deisolation";
      } else if (!isIsolated && isolationRequested) {
        return "Pending Isolation";
      } else {
        return "Not Isolated";
      }
    },
  },
  {
    title: "Excluded from Auto Isolation",
    field: "isExcluded",
    searchable: true,
    hideDetails: true,
    autobot: true,
    renderValue: (column, model) => (model[column.field] ? <CheckIcon /> : ""),
  },
  {
    title: "Last modified",
    field: "modified",
    searchable: true,
    renderValue: (column, model) => renderModifiedCell(column, model),
  },
];

export const deviceDetailsFields = deviceFields.filter(
  ({ hideDetails }) => !hideDetails
);

export const getDevicesTableColumns = (isAutobotEnabled) => {
  // if user does not have autobot feature,
  // do not show autobot specific columns
  if (!isAutobotEnabled) {
    return deviceFields
      .filter(({ searchable }) => searchable)
      .filter(({ autobot }) => !autobot);
  }

  return deviceFields.filter(({ searchable }) => searchable);
};

export const getInstallationTableColumns = (curDeviceCount, orgSeatCount) => {
  return [
    {
      title: "Description",
      field: "description",
      searchable: true,
    },
    {
      title: "Installed Devices",
      subTitle: `${curDeviceCount}/${orgSeatCount}`,
      field: "agentCount",
      searchable: true,
    },
    {
      title: "Device Limit Per Key",
      field: "agentSeatCount",
      searchable: true,
    },
  ];
};

export const deviceTableFilters = [
  {
    label: "All devices",
    filterMethod: (allDevices) => allDevices,
  },
  // TODO: Add in lastSeen filter once we have figured out all of the details for the 'alive' property
  // {
  //     label: 'Last seen over 24 hours ago',
  //     filterMethod: () => alert('TODO: 24 hour filter, do somethin\' with it')
  // },
  {
    label: "Online",
    filterMethod: (allDevices) => getFilteredDevices(allDevices, "alive", true),
  },
  {
    label: "Offline",
    filterMethod: (allDevices) =>
      getFilteredDevices(allDevices, "alive", false),
  },
  {
    label: "Isolated",
    filterMethod: (allDevices) =>
      getFilteredDevices(allDevices, "isIsolated", true),
  },
  {
    label: "Not isolated",
    filterMethod: (allDevices) =>
      getFilteredDevices(allDevices, "isIsolated", false),
  },
  {
    label: "Windows",
    filterMethod: (allDevices) =>
      getFilteredDevices(allDevices, "platformName", "Windows"),
  },
  {
    label: "macOS",
    filterMethod: (allDevices) =>
      getFilteredDevices(allDevices, "platformName", "macOS"),
  },
  {
    label: "Linux",
    filterMethod: (allDevices) =>
      getFilteredDevices(allDevices, "platformName", "Linux"),
  },
  // There isn't a 'Unresolved findings' column in the table currently, so I commented this one out for now
  // {
  //     label: 'Unresolved findings',
  //     filterMethod: () => alert('TODO: Unresolved finding filter, do somethin\' with it')
  // }
];

export const getFilteredDevices = (
  allDevicesArray = [],
  fieldToFilter,
  fieldValue
) => {
  // if no field is specified, return all devices
  if (!fieldToFilter) return allDevicesArray;

  // fieldValue is the field's value from the device model that the quick filter is matching the filtered list against
  if (typeof fieldValue === "boolean") {
    if (fieldValue) {
      return allDevicesArray.filter((device) => device[fieldToFilter]);
    } else {
      return allDevicesArray.filter((device) => !device[fieldToFilter]);
    }
  } else if (typeof fieldValue === "string") {
    return allDevicesArray.filter(
      (device) => device[fieldToFilter] === fieldValue
    );
  }
};

export const sendEmailToSalesTeam = async (orgName) => {
  const message = new Message({
    type: 30,
    recipient: "sales-direct-ae@blumira.com",
    subject: "Blumira Agent Activation Request",
    body: `Blumira Organization "${orgName}" requested to have the Blumira Agent feature enabled on ${moment().format(
      "MMMM Do YYYY, h:mm:ss a"
    )}.`,
  });

  message.create();
};

export const setLogShippingFeature = async (orgModelJSON, userEmail) => {
  const orgModel = new Org(orgModelJSON);

  // a little hack to get license_features from config because
  // current org in page views is not stored as a model
  const existingLicenseFeatures = get(
    orgModel,
    ["config", "license_features"],
    []
  );

  // for getting a starting number to set as agent seat count
  // for MSP child organziations - default to 5 for NFR accounts
  const agentSeatCountToSet = get(orgModel, ["config", "userCount"], 5);

  // set new LOGSHIPPING feature on model, which grants access
  // to the Blumira Agent portion of the web app
  orgModel.set({
    agentSeatCount: agentSeatCountToSet,
    licenseFeatures: [...existingLicenseFeatures, "LOGSHIPPING"],
  });

  // update org model with LOGSHIPPING feature
  const updatedOrgModelToReturn = await orgModel.update();

  // send summary email to MSP team for internal review
  const messageToSend = new Message({
    type: 30,
    recipient: "msp@blumira.com",
    subject: "MSP Organization: Blumira Agent Activation",
    body: `Blumira Agent feature was activated for MSP Organization "${orgModel.name}" by ${userEmail}`,
  });

  messageToSend.create();

  // return model to page view
  return updatedOrgModelToReturn;
};

export const getPageActionToDispatch = (model) => {
  const endTime = moment
    .utc()
    .endOf("minute")
    .format("YYYY-MM-DDTHH:mm:ss.SSS999");
  const startTime = moment
    .utc()
    .add(-7, "days")
    .startOf("minute")
    .format("YYYY-MM-DDTHH:mm");

  return {
    type: "PAGE",
    payload: {
      id1: model.platformReport,
      orgId: model.orgId,
      toplevel: "reporting",
      secondlevel: "builder",
    },
    query: {
      "Logger.eq": model.id,
      "created.lt": endTime,
      "created.gt": startTime,
    },
  };
};

// return a string to populate the MUI alert when a user experiences
// a failure self-activating the blumira agent in their org
export const getErrorMessageToSet = (organization = {}) => {
  return `Oops, we had a problem activating Blumira Agent. Please contact support for assistance.`;
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { User } from "lib/models/User";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SimpleTable from "views/Components/SimpleTable";
import ActionDialog from "views/Components/ActionDialog";
import SimpleModelForm from "views/Components/SimpleModelForm";
import moment from "moment-timezone";
import {
  addUserToOrg,
  createUser,
  deleteUserWithPageActions,
  updateUser,
  updateUserWithPageActions,
  resetMFA,
} from "redux/actions/Users";
import { Alert } from "@mui/material";

import Request from "lib/api/Request";
import { isNil } from "lodash";

export const UsersPageView2 = ({
  ready,
  orgId,
  currentUser,
  locations,
  addNewUser,
  addExistingUser,
  editUser,
  deleteUser,
  removeUserFromOrg,
  resetMFA,
  routeToPage,
  isOrgAParentOrg,
}) => {
  const [open, setOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [addExistingUserModalOpen, setAddExistingUserModalOpen] =
    useState(false);
  const [selectedModel, setSelectedModel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [deleteSelfModal, setDeleteSelfModal] = useState(false);
  const [resetMFAModal, setResetMFAModal] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [locationsOptions, setLocationsOptions] = useState([]);

  useEffect(() => {
    if (orgId && locations.length) {
      const newLocationsOptions = locations.map((loc) => {
        let name = loc.name || "";
        let city = loc.city || "";
        let state = loc.state || "";
        let preSep = "";
        let postSep = "";
        let midSep = "";
        if (city || state) {
          preSep = " (";
          postSep = ")";
        }
        if (city && state) {
          midSep = ", ";
        }
        return {
          value: { id: loc.id, orgId: orgId },
          label: name + preSep + city + midSep + state + postSep,
        };
      });

      setLocationsOptions(newLocationsOptions);
    }
  }, [locations, orgId]);

  const loadUsers = () => {
    setUsersLoading(true);
    // Can't use redux fetchUsersForOrg because it tramples over axios headers
    const request = new Request("/user");
    request
      .get()
      .then((allUsers) =>
        allUsers.filter(
          (user) => user.orgs.filter((o) => o.id === orgId).length > 0
        )
      )
      .then((filteredUsers) => {
        setUsers(filteredUsers);
        setUsersLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
  }, [orgId]);

  const handleAddNewOrEdit = () => {
    setSaving(true);
    const orgRoles = selectedModel.orgRoles
      ?.filter((role) => role.orgId === orgId)
      .map((role) => ({
        org_id: role.orgId,
        role_id: role.roleId,
      }));

    const locations = selectedModel.locations
      ?.filter((loc) => loc.orgId === orgId)
      .map((loc) => ({
        org_id: loc.orgId,
        id: loc.id,
      }));

    const user = {
      first_name: selectedModel.firstName,
      last_name: selectedModel.lastName,
      email: selectedModel.email,
      title: selectedModel.title,
      locations: locations,
      org_roles: orgRoles,
    };

    if (selectedModel.id) {
      //edit
      //if user hasn't accepted the tos yet
      //don't validate that field
      const editingSelf = selectedModel.id === currentUser.id;
      editUser(orgId, selectedModel.id, user, editingSelf)
        .then(() => {
          loadUsers();
          handleClose();
          setSaving(false);
        })
        .catch((err) => {
          setErrorMessage(err.toString());
          setSaving(false);
        });
    } else {
      //add new
      addNewUser(orgId, user)
        .then(() => {
          loadUsers();
          handleClose();
          setSaving(false);
        })
        .catch((err) => {
          setErrorMessage(err.toString());
          setSaving(false);
        });
    }
  };

  const handleAddExisting = () => {
    setSaving(true);
    addExistingUser(orgId, selectedModel.email, selectedModel.orgRoles)
      .then(() => {
        loadUsers();
        handleExistingClose();
        setSaving(false);
      })
      .catch((err) => {
        setErrorMessage(err.toString());
        setSaving(false);
      });
  };

  const checkSelfDelete = (user) => {
    //check and prevent deleting yourself
    if (user.id === currentUser.id) {
      setDeleteSelfModal(true);
    } else {
      setOpenAction(true);
    }
  };

  const handleDelete = () => {
    setSaving(true);
    //if user exists in other orgs
    //update the user to remove only from current org
    if (selectedModel.orgs && selectedModel.orgs.length > 1) {
      const updated = {};

      updated.org_roles = selectedModel.orgRoles
        .filter((role) => role.orgId !== orgId)
        .map((role) => ({
          org_id: role.orgId,
          role_id: role.roleId,
        }));

      removeUserFromOrg(orgId, selectedModel.id, updated).then(() => {
        loadUsers();
        setOpenAction(false);
        setSaving(false);
      });
    } else {
      //if user only exists in current org, just delete the user completely
      deleteUser(orgId, selectedModel.id).then(() => {
        loadUsers();
        setOpenAction(false);
        setSaving(false);
      });
    }
  };

  const handleMFAReset = () => {
    if (selectedModel.id) {
      setSaving(true);
      resetMFA(selectedModel.id)
        .then(() => {
          setSuccessMessage(
            "Successfully reset this user's two-factor authenticators."
          );
          setErrorMessage("");
          setResetMFAModal(false);
          setSaving(false);
        })
        .catch((err) => {
          setErrorMessage(
            "Unable to reset this user's two-factor authenticators. Please refresh and try again. If this error persists, please contact support@blumira.com."
          );
          setSaving(false);
        });
    } else {
      setErrorMessage(
        "Unable to find this user. Please refresh and try again. If this error persists, please contact support@blumira.com."
      );
    }
  };

  const handleEditUserNotifications = () => {
    routeToPage({
      payload: {
        orgId: orgId,
        toplevel: "user",
        secondlevel: "alertsettings",
        id1: selectedModel.id,
      },
    });
  };

  const handleClose = (action) => {
    selectedModel.reset();
    setErrorMessage("");
    setSuccessMessage("");
    setOpen(false);
  };

  const handleExistingClose = (action) => {
    selectedModel.reset();
    setErrorMessage("");
    setAddExistingUserModalOpen(false);
  };

  const handleTableRowClick = (rowDetails) => {
    //rowDetails, in this case, is really just the user info/model
    setSelectedModel(rowDetails);
    setOpen(true);
  };

  const mspRolesOptions = [
    { value: { orgId: orgId, roleId: 10 }, label: "Administrator" },
    { value: { orgId: orgId, roleId: 40 }, label: "Responder" },
  ];

  const rolesOptions = [
    { value: { orgId: orgId, roleId: 10 }, label: "Administrator" },
    { value: { orgId: orgId, roleId: 20 }, label: "Manager" },
    { value: { orgId: orgId, roleId: 40 }, label: "Responder" },
    { value: { orgId: orgId, roleId: 50 }, label: "Monitor" },
  ];

  const getRoles = (column, model) => {
    //filter only the user roles that correspond to the current orgId
    //then map the roleId
    return model[column.field]
      .filter((v) => v.orgId === orgId)
      .map((r) => {
        switch (r.roleId) {
          case 10:
            return "Administrator";
          case 20:
            return "Manager";
          case 30:
            //The Analyst role was removed as a selectable role a while ago
            //but we need to leave this case here in getRoles
            //so that any old users who may still be in the system as Analysts
            //will still show as such in the Users table
            return "Analyst";
          case 40:
            return "Responder";
          case 50:
            return "Monitor";
          default:
            return "";
        }
      })
      .sort()
      .join(", ");
  };

  const rolesVals = () => {
    //filter only the user roles that correspond to the current orgId
    //and filter the full roles list by the selected user's role(s)
    let userRolesOptions;

    // determine which list of roles to filter based off of
    if (isOrgAParentOrg) {
      userRolesOptions = mspRolesOptions;
    } else {
      userRolesOptions = rolesOptions;
    }

    return userRolesOptions
      .map((opt) => opt.value)
      .filter((o) =>
        selectedModel.orgRoles
          ?.filter((v) => v.orgId === orgId)
          .some((e) => e.orgId === o.orgId && e.roleId === o.roleId)
      );
  };

  const getLocations = (column, model) => {
    //filter only the locations that correspond to the current orgId
    //then map the city + state + country
    let city = "";
    let state = "";
    let country = "";
    return model[column.field]
      .filter((l) => l.orgId === orgId)
      .map((l) => {
        if (l.city !== null && l.city !== "") {
          city = l.city + ", ";
        }
        if (l.state !== null && l.state !== "") {
          state = l.state + ", ";
        }
        if (l.country !== null && l.country !== "") {
          country = l.country;
        }
        return city + state + country;
      })
      .join(", ");
  };

  const locsVals = () => {
    //filter only the locations that correspond to the current orgId
    //and filter the full locations list by the selected user's locations(s)
    return locationsOptions
      .map((opt) => opt.value)
      .filter((o) =>
        selectedModel.locations
          ?.filter((v) => v.orgId === orgId)
          .some((e) => e.orgId === o.orgId && e.id === o.id)
      );
  };

  const lastLoginVal = () => {
    const lastLogin = selectedModel.lastLogin;
    if (lastLogin) {
      const loginDate = moment
        .tz(lastLogin.created, "utc")
        .tz(moment.tz.guess(true))
        .format("lll z");

      return `${lastLogin.city}, ${lastLogin.ip}, ${loginDate}`;
    } else {
      return "User never logged in";
    }
  };

  const columns = [
    {
      title: "First Name",
      field: "firstName",
      searchable: true,
    },
    {
      title: "Last Name",
      field: "lastName",
      searchable: true,
    },
    {
      title: "Roles",
      field: "orgRoles",
      renderValue: getRoles,
      searchable: true,
    },
    {
      title: "Email",
      field: "email",
      searchable: true,
    },
    {
      title: "Locations",
      field: "locations",
      renderValue: getLocations,
      searchable: true,
    },
  ];

  return (
    <div className="new-users-page">
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">
          {selectedModel.id && selectedModel.name
            ? `Edit ${selectedModel.name}`
            : selectedModel.id
            ? `Edit ${selectedModel.email}`
            : "New User"}
        </DialogTitle>
        {successMessage && (
          <DialogContent>
            <Alert severity="success">{successMessage}</Alert>
          </DialogContent>
        )}
        {errorMessage && (
          <DialogContent>
            <Alert severity="error">{errorMessage}</Alert>
          </DialogContent>
        )}
        <SimpleModelForm
          spacing={1}
          model={selectedModel}
          onClose={handleClose}
          isSaving={isSaving}
          actions={
            selectedModel.id //edit user
              ? [
                  {
                    title: "Edit User Notifications",
                    type: "button",
                    action: handleEditUserNotifications,
                    variant: "contained",
                  },
                  {
                    title: "Reset Two-Factor Authenticators",
                    type: "button",
                    action: () => setResetMFAModal(true),
                    variant: "contained",
                  },
                  {
                    title: "Cancel",
                    type: "button",
                    action: handleClose,
                  },
                  {
                    title: "Save User",
                    type: "button",
                    action: handleAddNewOrEdit,
                    validate: true,
                  },
                ]
              : //new user
                [
                  {
                    title: "Cancel",
                    type: "button",
                    action: handleClose,
                  },
                  {
                    title: "Add User",
                    type: "button",
                    action: handleAddNewOrEdit,
                    validate: true,
                  },
                ]
          }
          layout={
            selectedModel.id //edit user
              ? [
                  { field: "firstName", xs: 6 },
                  { field: "lastName", xs: 6 },
                  { field: "email", xs: 6 },
                  { field: "title", xs: 6 },
                  { field: "orgRoles", xs: 12 },
                  { field: "locations", xs: 12 },
                  { field: "id", xs: 12 },
                  { field: "lastLogin", xs: 12 },
                ]
              : //new user
                [
                  { field: "firstName", xs: 6 },
                  { field: "lastName", xs: 6 },
                  { field: "email", xs: 6 },
                  { field: "title", xs: 6 },
                  { field: "orgRoles", xs: 12 },
                  { field: "locations", xs: 12 },
                ]
          }
          fields={{
            firstName: {
              type: "text",
              default: "",
              label: "First Name",
            },
            lastName: {
              type: "text",
              default: "",
              label: "Last Name",
            },
            email: selectedModel.id //edit user
              ? {
                  type: "text",
                  default: "",
                  label: "Email",
                  helperText: "",
                  readOnly: true,
                  plainText: true,
                }
              : //new user
                {
                  type: "text",
                  default: "",
                  label: "Email",
                },
            title: {
              type: "text",
              default: "",
              label: "Title",
            },
            orgRoles: {
              type: "multiselect",
              label: "Roles",
              lookup: isOrgAParentOrg ? mspRolesOptions : rolesOptions,
              value: rolesVals(),
            },
            locations: {
              type: "multiselect",
              label: "Locations",
              lookup: locationsOptions,
              value: locsVals(),
            },
            id: {
              type: "text",
              label: "User ID",
              readOnly: true,
              plainText: true,
            },
            lastLogin: {
              type: "text",
              label: "Last Login",
              readOnly: true,
              plainText: true,
              value: lastLoginVal(),
            },
          }}
        />
      </Dialog>
      <ActionDialog
        open={openAction}
        title={`Delete ${selectedModel.name}`}
        description="Are you sure you want to delete this user?"
        actions={[
          {
            title: "Cancel",
            action: () => setOpenAction(false),
          },
          {
            title: "Delete",
            action: handleDelete,
          },
        ]}
      />
      <ActionDialog
        open={deleteSelfModal}
        title={`You Can't Delete Yourself`}
        description="To remove yourself from the organization please contact an Administrator within your organization, or contact support@blumira.com"
        actions={[
          {
            title: "Dismiss",
            action: () => setDeleteSelfModal(false),
          },
        ]}
      />
      <ActionDialog
        open={resetMFAModal}
        title={`Reset Two-Factor Authenticators`}
        description={
          <span>
            Are you sure you want to reset all two-factor authenticators for{" "}
            <strong>
              {selectedModel.name || selectedModel.email || "this user"}
            </strong>
            ?
            <br />
            <br />
            The next time this person logs into Blumira, a second factor will
            not be required, and they will need to set up a new authenticator at
            that time.
            <br />
            <br />
            They will also receive an email with instructions on how to proceed.
          </span>
        }
        actions={[
          {
            title: "Cancel",
            action: () => setResetMFAModal(false),
          },
          {
            title: "Reset",
            action: handleMFAReset,
          },
        ]}
      />
      <Dialog
        onClose={handleExistingClose}
        aria-labelledby="simple-dialog-title"
        open={addExistingUserModalOpen}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">Add Existing User</DialogTitle>
        {errorMessage && (
          <DialogContent>
            <Alert severity="error">{errorMessage}</Alert>
          </DialogContent>
        )}
        <SimpleModelForm
          spacing={1}
          model={selectedModel}
          onClose={handleExistingClose}
          isSaving={isSaving}
          actions={[
            {
              title: "Cancel",
              type: "button",
              action: handleExistingClose,
            },
            {
              title: "Add User",
              type: "button",
              action: handleAddExisting,
              validate: true,
            },
          ]}
          layout={[
            { field: "email", xs: 12 },
            { field: "orgRoles", xs: 12 },
          ]}
          fields={{
            email: {
              type: "text",
              default: "",
              label: "Email",
            },
            orgRoles: {
              type: "multiselect",
              label: "Roles",
              lookup: isOrgAParentOrg ? mspRolesOptions : rolesOptions,
              value: rolesVals(),
            },
          }}
        />
      </Dialog>
      <SimpleTable
        isNorthStar
        isFetching={!ready || usersLoading}
        columns={columns}
        rowClick={handleTableRowClick}
        data={users}
        initialOrderBy={["firstName", "asc"]}
        // only superadmins can add existing users
        toolbarActions={
          currentUser?.superadmin
            ? [
                {
                  icon: AddCircleIcon,
                  tooltip: "Add Existing User",
                  onClick: (event, model) => {
                    setSelectedModel(new User());
                    setAddExistingUserModalOpen(true);
                  },
                },
                {
                  icon: AddCircleIcon,
                  tooltip: "Add New User",
                  onClick: (event, model) => {
                    setSelectedModel(new User());
                    setOpen(true);
                  },
                },
              ]
            : [
                {
                  icon: AddCircleIcon,
                  tooltip: "Add New User",
                  onClick: (event, model) => {
                    setSelectedModel(new User());
                    setOpen(true);
                  },
                },
              ]
        }
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit User",
            onClick: (event, model) => {
              setSelectedModel(model);
              setOpen(true);
            },
          },
          {
            icon: DeleteIcon,
            tooltip: "Delete User",
            onClick: (event, model) => {
              setSelectedModel(model);
              checkSelfDelete(model);
            },
          },
        ]}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addNewUser: (orgId, data) => dispatch(createUser({ orgId, data })),
  addExistingUser: (orgId, email, roles) =>
    dispatch(addUserToOrg({ orgId, email, roles })),
  editUser: (orgId, personId, data, editingSelf) =>
    dispatch(updateUser({ orgId, personId, data, editingSelf })),
  deleteUser: (orgId, personId) =>
    dispatch(deleteUserWithPageActions({ orgId, personId })),
  removeUserFromOrg: (orgId, personId, data) =>
    dispatch(updateUserWithPageActions({ orgId, personId, data })),
  resetMFA: (personId) => dispatch(resetMFA({ personId })),
  routeToPage: (props) => {
    dispatch({ type: "PAGE", ...props });
  },
});

const mapStateToProps = (state) => {
  const { session } = state;
  const orgId = state.location.payload.orgId;
  const currentOrg = session.settings.userOrgs.find(({ id }) => id === orgId);

  const isOrgAParentOrg =
    currentOrg && currentOrg?.market === 20 && isNil(currentOrg?.parentId);

  return {
    ready: state.page.ready,
    orgId: state.location.payload.id1
      ? state.location.payload.id1
      : state.location.payload.orgId,
    currentUser: state.session?.settings?.user,
    isOrgAParentOrg,
  };
};

UsersPageView2.defaultProps = {
  ready: false,
  users: [],
  locations: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPageView2);

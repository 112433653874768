import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";

import { TextField } from "./Form";
import Alert from "./Alert";
import Submitted from "./Submitted";
import HelpToolTip from "./HelpToolTip";
import MyAlertsTable, { CheckBox } from "./MyAlertsTable";
import { updateUser } from "../../redux/actions/Users";
import Logger from "../../lib/logger";

import LicenseRestriction from "./License";

import "./NotificationsForm.scss";

import { getValue } from "../../utils/index";
import { validateEmail, validatePhone } from "./Form/validators";

const logger = Logger("NotificationsForm");

/*
Constants
*/

export const CONFIG_ALERTS = "config_alerts";
export const SEPARATOR = "/";

const ALERTS = `${CONFIG_ALERTS}${SEPARATOR}alerts`;
const FREQUENCY = `${CONFIG_ALERTS}${SEPARATOR}alert_frequency`;
const EMAIL_ADDR = `${CONFIG_ALERTS}${SEPARATOR}contact_details${SEPARATOR}email_address`;
const FINDING_ALERTS = `${CONFIG_ALERTS}${SEPARATOR}notify_all_org_comments_email`;
const INITIAL_RESPONDER_EMAILS = `${CONFIG_ALERTS}${SEPARATOR}email_on_initial_responder_ownership`;
const TEXT_NUMBER = `${CONFIG_ALERTS}${SEPARATOR}contact_details${SEPARATOR}text_number`;
const VOICE_NUMBER = `${CONFIG_ALERTS}${SEPARATOR}contact_details${SEPARATOR}voice_number`;

export const FIELDS_TO_REMOVE_FOR_SELECTED = [
  ALERTS,
  FREQUENCY,
  EMAIL_ADDR,
  FINDING_ALERTS,
  INITIAL_RESPONDER_EMAILS,
  TEXT_NUMBER,
  VOICE_NUMBER,
];

const THREAT_LABELS = {
  f_40: "System Notifications",
  f_30: "Threat Notifications",
  f_20: "Suspect Notifications",
  f_15: "Risk Notifications",
  f_10: "Operational Notifications",
};

const CHECKBOX_COLUMN_NAMES = ["voice", "text", "email"];

const FINDING_ALERTS_ALL_ORGS_CHAR = "*";

const FINDING_ALERTS_TOOLTIP_TEXT = `
  By enabling this setting, you will be notified on any
  new comment made by another user in your organization on every finding,
  even on findings on which you are not an owner.
  Owners of specific findings as well as previous comment posters
  on the finding will always be notified.
  `;

const INITIAL_RESPONDER_EMAIL_TOOLTIP_TEXT = `
  By enabling this setting, you will be notified when a responder takes initial
  ownership of any finding in your organization.
  `;

/*
Helpers
*/

const makeTableRow = ({ priority, pLabel, fLabel }) => {
  const out = { priority };

  CHECKBOX_COLUMN_NAMES.forEach((name) => {
    out[name] =
      `${CONFIG_ALERTS}${SEPARATOR}${fLabel}` +
      `${SEPARATOR}${pLabel}${SEPARATOR}${name}`;
  });

  return out;
};

// The table rows (i.e. table "data") are constant.
// The state of the checkboxes comes from a 'selected'
// object through the table's 'Cell' function.
const TABLE_ROWS = {
  f_10: [makeTableRow({ priority: "", pLabel: "*", fLabel: "f_10" })],
  f_15: [
    makeTableRow({ priority: "Priority 1", pLabel: "p1", fLabel: "f_15" }),
    makeTableRow({ priority: "Priority 2", pLabel: "p2", fLabel: "f_15" }),
    makeTableRow({ priority: "Priority 3", pLabel: "p3", fLabel: "f_15" }),
  ],
  f_20: [
    makeTableRow({ priority: "Priority 1", pLabel: "p1", fLabel: "f_20" }),
    makeTableRow({ priority: "Priority 2", pLabel: "p2", fLabel: "f_20" }),
    makeTableRow({ priority: "Priority 3", pLabel: "p3", fLabel: "f_20" }),
  ],
  f_30: [
    makeTableRow({ priority: "Priority 1", pLabel: "p1", fLabel: "f_30" }),
    makeTableRow({ priority: "Priority 2", pLabel: "p2", fLabel: "f_30" }),
    makeTableRow({ priority: "Priority 3", pLabel: "p3", fLabel: "f_30" }),
  ],
  f_40: [
    makeTableRow({
      priority: "Sensor goes online or offline",
      pLabel: "notify_on_sensor_down",
      fLabel: "f_40",
    }),
    makeTableRow({
      priority: "Sensor resources are low",
      pLabel: "notify_on_sensor_low_resources",
      fLabel: "f_40",
    }),
    makeTableRow({
      priority: "Sensor stopped sending logs",
      pLabel: "notify_on_sensor_logs_stopped",
      fLabel: "f_40",
    }),
    makeTableRow({
      priority: "Maximum Deployable Agents exceeded (MSP)",
      pLabel: "notify_on_max_agents_exceeded",
      fLabel: "f_40",
    }),
    makeTableRow({
      priority: "Cloud Connector errors and recoveries",
      pLabel: "cc_notify_on_error_and_ok",
      fLabel: "f_40",
    }),
    makeTableRow({
      priority: "Cloud Connector persistent errors",
      pLabel: "cc_notify_on_still_error",
      fLabel: "f_40",
    }),
    // TODO: When this alert is re-enabled, uncomment.
    // makeTableRow({
    //   priority: "Cloud Connector data outdated",
    //   pLabel: "cc_notify_on_stale_data",
    //   fLabel: "f_40",
    // }),
    makeTableRow({
      priority: "Cloud Connector failure to complete initialization",
      pLabel: "cc_notify_on_init_stuck",
      fLabel: "f_40",
    }),
  ],
};

// Takes a string and returns 'true' or 'false' showing if the string
// is truthy or falsy.
const strToBoolean = (text) => (text ? true : false);

// Takes a nested object 'obj' and returns a "flat" object whose keys are paths
// of 'obj' concatenated into strings using 'separator'.
const flatten = (prefix = null, obj, separator = "/") => {
  const fullPrefix = prefix === null ? "" : `${prefix}${separator}`;
  const out = {};

  for (const [key, val] of Object.entries(obj)) {
    if (Array.isArray(val) || typeof val !== "object" || val === null) {
      out[`${fullPrefix}${key}`] = val;
    } else {
      const flatVal = flatten((prefix = null), (obj = val), separator);

      for (const [secondKey, secondVal] of Object.entries(flatVal)) {
        out[`${fullPrefix}${key}${separator}${secondKey}`] = secondVal;
      }
    }
  }

  return out;
};

// Takes a "flat" object 'obj' and returns a nested object.
// This is the reverse of 'flatten' - the keys of the input 'obj'
// are the paths of the returned object, concatenated using 'separator'.
const unflatten = (obj, separator = SEPARATOR) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    const words = key.split(separator);
    let current = newObj;

    for (let i = 0; i < words.length - 1; i += 1) {
      const w = words[i];

      if (Object.prototype.hasOwnProperty.call(current, w)) {
        current = current[w];
      } else {
        current[w] = {};
        current = current[w];
      }
    }

    current[words[words.length - 1]] = val;
  });

  return newObj;
};

// Takes 'user' as argument and returns initial values for the form.
const getInitialValues = ({ user }) => {
  // Copy user.config_alerts to configAlerts, using defaults for missing values.
  let configAlerts = {};
  if (user && user.config_alerts) {
    configAlerts = { ...user.config_alerts };
  }
  if (user && user.configAlerts) {
    configAlerts = { ...user.configAlerts };
  }
  if (!configAlerts.contact_details) {
    configAlerts.contact_details = {};
  }
  if (!configAlerts.contact_details.text_number) {
    configAlerts.contact_details.text_number = "";
  }
  if (!configAlerts.contact_details.voice_number) {
    configAlerts.contact_details.voice_number = "";
  }
  if (!configAlerts.contact_details.email_address) {
    configAlerts.contact_details.email_address = user.email;
  }
  if (!configAlerts.email_on_initial_responder_ownership) {
    configAlerts.email_on_initial_responder_ownership = [
      FINDING_ALERTS_ALL_ORGS_CHAR,
    ];
  }

  const initialValues = flatten(CONFIG_ALERTS, configAlerts);

  return initialValues;
};

const findingAlertNeverSet = (arr) =>
  arr &&
  arr.length &&
  arr.length === 1 &&
  arr[0] === FINDING_ALERTS_ALL_ORGS_CHAR;

const initialFindingAlert = ({ orgId, user }) => {
  const configAlerts = user.config_alerts || user.configAlerts || {};
  const findingAlerts = configAlerts.notify_all_org_comments_email || [];

  // return true if findingAlerts === ['*']
  if (findingAlertNeverSet(findingAlerts)) {
    return true;
  }

  const orgAlert = findingAlerts.find((elt) => elt === orgId);

  // if orgId in orgAlert, return true
  return orgAlert !== undefined;
};

const initialInitialResponderEmail = ({ orgId, user }) => {
  const configAlerts = user.config_alerts || user.configAlerts || {};
  const initialResponderEmails =
    configAlerts.email_on_initial_responder_ownership || ["*"];

  // return true if initialResponderEmails === ['*']
  if (findingAlertNeverSet(initialResponderEmails)) {
    return true;
  }

  const orgAlert = initialResponderEmails.find((elt) => elt === orgId);

  // if orgId in orgAlert, return true
  return orgAlert !== undefined;
};

// Takes 'user' as argument and returns 'selected'
// calculated from 'user.config_alerts'.
// The 'selected' object contains the 'checked/not checked'
// flag for each table cell.
const makeSelected = (user) => {
  let selected = {};

  if (user) {
    const configAlerts = user.configAlerts
      ? user.configAlerts
      : user.config_alerts || {};
    selected = flatten(CONFIG_ALERTS, configAlerts);
    FIELDS_TO_REMOVE_FOR_SELECTED.forEach((elt) => {
      delete selected[elt];
    });
  }

  return selected;
};

const updateFindingAlerts = ({
  orgId,
  findingAlert,
  touched = false,
  orgArr,
}) => {
  if (!touched) {
    return orgArr;
  }

  if (findingAlertNeverSet(orgArr)) {
    return findingAlert ? [orgId] : [];
  }

  const newOrgArr = getValue(
    [],
    orgArr?.filter((elt) => elt !== orgId)
  );

  if (findingAlert) {
    newOrgArr.push(orgId);
  }

  return newOrgArr;
};

const PREFIX = "NotificationForm";
const classes = {
  formGroupLabel: `${PREFIX}-formGroupLabel`,
  formGroupHeader: `${PREFIX}-formGroupHeader`,
  formCheckboxText: `${PREFIX}-formCheckboxText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.formGroupLabel}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${classes.formGroupHeader}`]: {
    color: theme.palette.text.primary,
  },
  [`& .${classes.formCheckboxText}`]: {
    color: theme.palette.text.primary,
  },
}));

/*
Component
*/

// Displays  a form containing the email and phone numbers fields, and the
// notification choices.
const NotificationsForm = ({
  orgId,
  user,
  updateAlerts,
  updateAlertsOverride,
  editingSelf,
}) => {
  const personId = user.id;

  // The checked/not checked state of the cells
  const [selected, setSelected] = useState({});
  // Show/not show 'Submitted' modal
  const [submitted, setSubmitted] = useState(false);
  // Display the submission error if present
  const [error, setError] = useState(null);
  // Disable/not disable the 'Save' button (e.g. on form validation errors).
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // Finding Comment Alerts checkbox state
  const [findingAlert, setFindingAlert] = useState(false);
  const [findingAlertTouched, setFindingAlertTouched] = useState(false);

  // Finding Comment Alerts tooltip
  const [findingCommentNotificationsTooltipTargetId] = useState(
    `alerts_finding_comments_${Math.random().toString().slice(2)}`
  );
  const [
    findingCommentNotificationsTooltipOpen,
    setFindingCommentNotificationsTooltipOpen,
  ] = useState(false);

  // Initial Responder Emails checkbox state
  const [initialResponderEmails, setInitialResponderEmails] = useState(false);
  const [initialResponderEmailsTouched, setInitialResponderEmailsTouched] =
    useState(false);

  // Initial Responder Emails tooltip
  const [initialResponderEmailsTooltipTargetId] = useState(
    `initial_responder_emails_${Math.random().toString().slice(2)}`
  );
  const [
    initialResponderEmailsTooltipOpen,
    setInitialResponderEmailsTooltipOpen,
  ] = useState(false);

  useEffect(() => {
    setSelected(makeSelected(user));
    setError(null);
  }, [user]);

  useEffect(() => {
    setFindingAlert(initialFindingAlert({ orgId, user }));
    setFindingAlertTouched(false);
  }, [user, orgId]);

  useEffect(() => {
    setInitialResponderEmails(initialInitialResponderEmail({ orgId, user }));
    setInitialResponderEmailsTouched(false);
  }, [user, orgId]);

  const updateSelections = (newSelected) => {
    setSelected({
      ...selected,
      ...newSelected,
    });
  };

  const toggleFindingAlert = () => {
    setFindingAlert(!findingAlert);
    setFindingAlertTouched(true);
  };

  const toggleInitialResponderEmails = () => {
    setInitialResponderEmails(!initialResponderEmails);
    setInitialResponderEmailsTouched(true);
  };

  // Finding alerts tooltip toggle
  const toggleFindingCommentNotificationsTooltip = () => {
    setFindingCommentNotificationsTooltipOpen(
      !findingCommentNotificationsTooltipOpen
    );
  };

  // Initial Responder Emails tooltip toggle
  const toggleInitialResponderEmailsTooltip = () => {
    setInitialResponderEmailsTooltipOpen(!initialResponderEmailsTooltipOpen);
  };

  if (updateAlertsOverride) {
    // Allow parent component to override the updateAlerts callback
    updateAlerts = updateAlertsOverride;
  }

  const update = async ({ config_alerts }) => {
    updateAlerts({
      orgId,
      personId,
      editingSelf,
      config_alerts,
    }).catch((err) => {
      setError(err);
    });
  };

  // Handles submission errors
  const handleError = (err) => {
    setError(err);
  };

  const dismissError = () => {
    setError(null);
  };

  const showSubmitted = () => {
    setSubmitted(true);
    setTimeout(() => setSubmitted(false), 2500);
  };

  // Throws errors if text/voice phone number is not provided,
  // but a corresponding notification is set
  const validateContacts = (values) => {
    const errors = {};

    const entries = Object.entries(selected);

    const reducer = (notificationType) => (accum, current) =>
      accum ||
      (current[0].split(SEPARATOR)[3] === notificationType && current[1]);

    if (values[TEXT_NUMBER].length > 0 && !validatePhone(values[TEXT_NUMBER])) {
      errors[TEXT_NUMBER] =
        "Please enter a valid phone number in the format: 123-456-7890";
    }

    const textNumRequired = entries.reduce(reducer("text"), false);
    if (textNumRequired && !values[TEXT_NUMBER]) {
      errors[TEXT_NUMBER] = "Number needed for text alerts";
    }

    if (
      values[VOICE_NUMBER].length > 0 &&
      !validatePhone(values[VOICE_NUMBER])
    ) {
      errors[VOICE_NUMBER] =
        "Please enter a valid phone number in the format: 123-456-7890";
    }

    const voiceNumRequired = entries.reduce(reducer("voice"), false);
    if (voiceNumRequired && !values[VOICE_NUMBER]) {
      errors[VOICE_NUMBER] = "Number needed for voice alerts";
    }
    if (!validateEmail(values[EMAIL_ADDR])) {
      errors[EMAIL_ADDR] = "Invalid email address";
    }
    return errors;
  };

  const customValidate = (values) => {
    const contactErrors = validateContacts(values);

    if (Object.keys(contactErrors).length > 0) {
      setSubmitDisabled(true); // block submission
    } else {
      setSubmitDisabled(false); // allow submission
    }
    return contactErrors;
  };

  return (
    <Root className="notification-form">
      {error && (
        <Alert
          color="danger"
          reshowToken={null}
          message={error.message}
          handleAction={dismissError}
        />
      )}

      {!error && submitted && (
        <Submitted
          isOpen={submitted}
          body={`Updating notification settings for user ${user.email}...`}
          size="lg"
        />
      )}

      <Formik
        initialValues={getInitialValues({ user })}
        enableReinitialize
        validate={customValidate}
        onSubmit={async (values) => {
          let newValues = unflatten(values);

          const newSelected = unflatten(selected);

          const config_alerts = {
            ...newValues.config_alerts,
            ...newSelected.config_alerts,
          };

          // this conditional is set in place for a handful of users
          // who updated their notification preferences while an account
          // service bug was being resolved regarding opterational notifs
          if (config_alerts["f_10"]["pAny"]) {
            if (config_alerts["f_10"]["*"])
              delete config_alerts["f_10"]["pAny"];
          }

          if (config_alerts["maximum_deployable_agents_email"]) {
            delete config_alerts["maximum_deployable_agents_email"];
          }

          config_alerts.notify_all_org_comments_email = updateFindingAlerts({
            orgId,
            findingAlert,
            touched: findingAlertTouched,
            orgArr: config_alerts.notify_all_org_comments_email,
          });

          config_alerts.email_on_initial_responder_ownership =
            updateFindingAlerts({
              orgId,
              findingAlert: initialResponderEmails,
              touched: initialResponderEmailsTouched,
              orgArr: config_alerts.email_on_initial_responder_ownership,
            });

          newValues = {
            ...newValues,
            config_alerts,
          };

          setSubmitDisabled(true);

          let resp;
          try {
            resp = await update(newValues);
          } catch (err) {
            logger.error(err);

            handleError(err);
            setSubmitDisabled(false);
            return;
          }

          dismissError();
          setSubmitDisabled(false);
          showSubmitted();

          return resp;
        }}
      >
        {(props) => {
          const { values } = props;

          const fLabeltoSelected = {};

          // Create a map from f-labels to their portion of 'selected'
          Object.keys(THREAT_LABELS).forEach((label) => {
            const filtered = Object.keys(selected)
              .filter((key) => key.includes(label))
              .reduce((obj2, key) => {
                obj2[key] = selected[key];
                return obj2;
              }, {});

            fLabeltoSelected[label] = filtered;
          });

          // Enabled/disabled flags for text, voice, email columns
          const text = strToBoolean(values[TEXT_NUMBER]);
          const voice = strToBoolean(values[VOICE_NUMBER]);
          const email = strToBoolean(values[EMAIL_ADDR]);

          return (
            <form>
              <div className="form-group">
                <div className="row">
                  <LicenseRestriction requires="query.create">
                    <TextField
                      classes="form-control control-item"
                      labelClasses={classes.formGroupLabel}
                      type="text"
                      name={VOICE_NUMBER}
                      label="Voice Numbers"
                      placeholder="123-456-7890"
                      validation={{
                        phone: true,
                      }}
                      dataCy={"voiceNumberInput"} // for selecting with cypress
                      {...props}
                    />
                  </LicenseRestriction>
                </div>
                <div className="row">
                  <LicenseRestriction requires="query.create">
                    <TextField
                      classes="form-control control-item"
                      labelClasses={classes.formGroupLabel}
                      type="text"
                      name={TEXT_NUMBER}
                      label="Text Number"
                      placeholder="123-456-7890"
                      validation={{
                        phone: true,
                      }}
                      dataCy={"textNumberInput"} // for selecting with cypress
                      {...props}
                    />
                  </LicenseRestriction>
                </div>

                <div className="row">
                  <TextField
                    dataCy={"emailAddressInput"}
                    classes="form-control control-item user-email"
                    labelClasses={classes.formGroupLabel}
                    type="text"
                    name={EMAIL_ADDR}
                    label="Email"
                    placeholder="user@example.com"
                    required
                    validation={{
                      email: true,
                    }}
                    {...props}
                  />
                </div>
              </div>
              <br />

              <div className="form-group">
                {Object.keys(THREAT_LABELS).map((key) => (
                  <div key={key}>
                    <h2 className={classes.formGroupHeader}>
                      {THREAT_LABELS[key]}
                    </h2>
                    <MyAlertsTable
                      tableRows={TABLE_ROWS[key]}
                      selected={fLabeltoSelected[key]}
                      updateSelections={updateSelections}
                      text={text}
                      voice={voice}
                      email={email}
                      numberOfRows={TABLE_ROWS[key].length}
                    />
                    <br />
                  </div>
                ))}

                <div className="finding-comment-notification">
                  <CheckBox
                    checked={findingAlert}
                    handleClick={toggleFindingAlert}
                  />

                  <span className={classes.formCheckboxText}>
                    Email me on every new finding comment
                  </span>

                  <HelpToolTip
                    className="notifications-form-tooltip"
                    targetId={findingCommentNotificationsTooltipTargetId}
                    isOpen={findingCommentNotificationsTooltipOpen}
                    toggle={toggleFindingCommentNotificationsTooltip}
                  >
                    {FINDING_ALERTS_TOOLTIP_TEXT}
                  </HelpToolTip>
                </div>

                <div className="initial-responder-notification">
                  <CheckBox
                    checked={initialResponderEmails}
                    handleClick={toggleInitialResponderEmails}
                  />

                  <span className={classes.formCheckboxText}>
                    Email me when a responder takes initial ownership of a
                    finding
                  </span>

                  <HelpToolTip
                    className="notifications-form-tooltip"
                    targetId={initialResponderEmailsTooltipTargetId}
                    isOpen={initialResponderEmailsTooltipOpen}
                    toggle={toggleInitialResponderEmailsTooltip}
                  >
                    {INITIAL_RESPONDER_EMAIL_TOOLTIP_TEXT}
                  </HelpToolTip>
                </div>
              </div>
              <br />

              <Button
                type="submit"
                bsStyle="primary"
                disabled={submitDisabled}
                onClick={props.handleSubmit}
              >
                Save
              </Button>
            </form>
          );
        }}
      </Formik>
    </Root>
  );
};

NotificationsForm.propTypes = {
  user: PropTypes.shape({
    config_alerts: PropTypes.shape({}),
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  orgId: PropTypes.string,
  updateAlerts: PropTypes.func.isRequired,
  values: PropTypes.shape({}),
  handleSubmit: PropTypes.func, // Formik prop - added to silence the linter
};

NotificationsForm.defaultProps = {
  user: {
    config_alerts: null,
    email: "",
    id: "",
  },
  orgId: "",
  values: {},
  handleSubmit: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  updateAlerts: ({ orgId, personId, config_alerts, editingSelf }) =>
    dispatch(
      updateUser({
        orgId,
        personId,
        editingSelf,
        data: { config_alerts },
      })
    ),
});

export default connect(null, mapDispatchToProps)(NotificationsForm);

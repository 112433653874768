import React, { useEffect, useState } from "react";

import SimpleTable from "views/Components/SimpleTable";
import { MSPIntegrationLogo } from "./MSPIntegrationLogo";
import MSPIntegrationDialog from "../../Components/MSP/MSPIntegrationsDialog";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const MSPIntegrationsPageView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mspIntegrations, setMSPIntegrations] = useState([]); // eslint-disable-line no-unused-vars
  const [isAddIntegrationModalOpen, setIsAddIntegrationModalOpen] =
    useState(false);

  // const integrations = [
  //   {
  //     integrationType: "psa/connectwise",
  //     name: "Connectwise Manage",
  //     type: "Ticketing and Billing",
  //     sendFinding: "Enabled",
  //     lastConnection: "5 min ago, Jun 15, 2024 6:29AM EDT",
  //     integrationStatus: "OK",
  //   },
  // ];

  useEffect(() => {
    if (props.ready) {
      // setMSPIntegrations(integrations); // Uncomment to see hardcoded integration above in table
      setIsLoading(false);
    }
  }, [props.ready]);

  const renderLogoCell = (column, model) => {
    return (
      <MSPIntegrationLogo
        integrationType={model[column.field]}
        width="98"
        height="45"
      />
    );
  };

  const columns = [
    {
      field: "integrationType",
      renderValue: renderLogoCell,
      searchable: false,
    },
    {
      title: "Name",
      field: "name",
      searchable: true,
    },
    {
      title: "Type",
      field: "type",
      searchable: true,
    },
    {
      title: "Send Findings",
      field: "sendFinding",
      searchable: true,
    },
    {
      title: "Last API Connection",
      field: "lastConnection",
      searchable: true,
      icon: (column, model) => {
        return (
          <FiberManualRecordIcon
            style={{
              color:
                model.integrationStatus === "OK"
                  ? "#3BB273" // green
                  : "#E76F51", // red
            }}
          />
        );
      },
    },
  ];

  const toggleMSPIntegrationDialog = () => {
    setIsAddIntegrationModalOpen(!isAddIntegrationModalOpen);
  };

  return (
    <>
      <SimpleTable
        isNorthStar
        isFetching={isLoading}
        data={mspIntegrations}
        emptyText={<span>No MSP Integrations added yet</span>}
        columns={columns}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add MSP Integration",
            onClick: toggleMSPIntegrationDialog,
          },
        ]}
      />
      <MSPIntegrationDialog
        open={isAddIntegrationModalOpen}
        toggleModal={toggleMSPIntegrationDialog}
      />
    </>
  );
};

export default MSPIntegrationsPageView;

import { styled } from "@mui/material/styles";

const PREFIX = "IntegrationLogos";
export const classes = {
  container: `${PREFIX}-container`,
  awsTxt: `${PREFIX}-awsText`,
  awsSmile: `${PREFIX}-awsSmile`,
  duoGreen1: `${PREFIX}-duoGreen1`,
  duoGreen2: `${PREFIX}-duoGreen2`,
  m365Txt: `${PREFIX}-m365Txt`,
  m365sq1: `${PREFIX}-m365sq1`,
  m365sq2: `${PREFIX}-m365sq2`,
  m365sq3: `${PREFIX}-m365sq3`,
  m365sq4: `${PREFIX}-m365sq4`,
  azHubLogo: `${PREFIX}-azHubLogo`,
  sentinelTxt: `${PREFIX}-sentinelTxt`,
  sentinelLogo: `${PREFIX}-sentinelLogo`,
  mimecastText: `${PREFIX}-mimecastText`,
  webrootText: `${PREFIX}-webrootText`,
  umbrella: `${PREFIX}-umbrella`,
  googleText: `${PREFIX}-googleText`,
  gcpCloudRed: `${PREFIX}-gcpCloudRed`,
  gcpCloudBlue: `${PREFIX}-gcpCloudBlue`,
  gcpCloudGreen: `${PREFIX}-gcpCloudGreen`,
  gcpCloudYellow: `${PREFIX}-gcpCloudYellow`,
  carbonBlackText: `${PREFIX}-carbonBlackText`,
  jumpCloudText: `${PREFIX}-jumpCloudText`,
  jumpCloudPrimary: `${PREFIX}-jumpCloudPrimary`,
  jumpCloudSecondary: `${PREFIX}-jumpCloudSecondary`,
  oneLoginText: `${PREFIX}-oneLoginText`,
  onePasswordText: `${PREFIX}-onePasswordText`,
};

export const StyledSVG = styled("svg")(({ theme }) => ({
  [`& .${classes.container}`]: {
    textAlign: "center",
  },
  [`& .${classes.awsTxt}`]: {
    fill: theme.svgs.aws.txt.color,
  },
  [`& .${classes.awsSmile}`]: {
    fill: theme.svgs.aws.smile.color,
  },
  [`& .${classes.duoGreen1}`]: {
    fill: theme.svgs.duo.green1.color,
  },
  [`& .${classes.duoGreen2}`]: {
    fill: theme.svgs.duo.green2.color,
  },
  [`& .${classes.m365Txt}`]: {
    fill: theme.svgs.m365.txt.color,
  },
  [`& .${classes.m365sq1}`]: {
    fill: theme.svgs.m365.sq1.color,
  },
  [`& .${classes.m365sq2}`]: {
    fill: theme.svgs.m365.sq2.color,
  },
  [`& .${classes.m365sq3}`]: {
    fill: theme.svgs.m365.sq3.color,
  },
  [`& .${classes.m365sq4}`]: {
    fill: theme.svgs.m365.sq4.color,
  },
  [`& .${classes.azHubLogo}`]: {
    fill: theme.svgs.azHub.txt.color,
  },
  [`& .${classes.sentinelTxt}`]: {
    fill: theme.svgs.sentinel.txt.color,
  },
  [`& .${classes.sentinelLogo}`]: {
    fill: theme.svgs.sentinel.logo.color,
  },
  [`& .${classes.mimecastText}`]: {
    fill: theme.svgs.mimecast.txt.color,
  },
  [`& .${classes.webrootText}`]: {
    fill: theme.svgs.webroot.txt.color,
  },
  [`& .${classes.umbrella}`]: {
    fill: theme.svgs.umbrella.color,
  },
  [`& .${classes.googleText}`]: {
    fill: theme.svgs.google.txt.color,
  },
  [`& .${classes.gcpCloudRed}`]: {
    fill: "#ea4335",
  },
  [`& .${classes.gcpCloudBlue}`]: {
    fill: "#4285f4",
  },
  [`& .${classes.gcpCloudGreen}`]: {
    fill: "#34a853",
  },
  [`& .${classes.gcpCloudYellow}`]: {
    fill: "#fbbc05",
  },
  [`& .${classes.carbonBlackText}`]: {
    fill: theme.svgs.carbonBlack.txt.color,
  },
  [`& .${classes.jumpCloudText}`]: {
    fill: theme.svgs.jumpCloud.txt.color,
  },
  [`& .${classes.jumpCloudPrimary}`]: {
    fill: "#14A19C",
  },
  [`& .${classes.jumpCloudSecondary}`]: {
    fill: "#41C8C3",
  },
  [`& .${classes.oneLoginText}`]: {
    fill: theme.svgs.oneLogin.txt.color,
  },
  [`& .${classes.onePasswordText}`]: {
    fill: theme.svgs.onePass.txt.color,
  },
}));

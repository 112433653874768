import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";

export default function AlertMessage(props) {
  const [open, setOpen] = useState((props.open && props.message) || false);
  const [message, setMessage] = useState(props.message || ""); // eslint-disable-line no-unused-vars

  const placment = {
    vertical: props.vertical || "top",
    horizontal: props.horizontal || "center",
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.message) {
      setMessage(props.message);
      setOpen(true);
    }
  }, [props.open, props.message]);

  return (
    <div>
      <Snackbar
        anchorOrigin={placment}
        open={open}
        key={props.message}
        autoHideDuration={3000}
        onClose={handleClose}
        message={props.message}
      />
    </div>
  );
}

import { styled } from "@mui/material/styles";

const PREFIX = "MSPIntegrationLogos";
export const classes = {
  container: `${PREFIX}-container`,
};

export const StyledSVG = styled("svg")(({ theme }) => ({
  [`& .${classes.container}`]: {
    textAlign: "center",
  },
}));

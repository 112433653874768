import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Link from "redux-first-router-link";
import { processDataQueries } from "../../redux/actions/DataQuery";
import { fetchFindings } from "../../redux/actions/Findings";
import logo from "../../images/blumira-logo-white.svg";
import StatesPieChart from "../Pages/Dashboard/StatesPieChart";
import StatesTable from "../Pages/Dashboard/StatesTable";
import ActiveFindingsTable from "../Pages/Dashboard/ActiveFindingsTable";
import Worldmap from "../Pages/Dashboard/Worldmap";
import LocationsTable from "../Pages/Dashboard/LocationsTable";
import Scroller from "../Pages/Dashboard/Scroller";
import TrendsGraph from "../Pages/Dashboard/TrendsGraph";
import { logoutFromBlumira } from "../../redux/actions/Page";

const MonitorModeView = ({
  user,
  orgId,
  processAllDataQueries,
  processDataQuery,
  logout,
  dataQueries,
  dispatch,
}) => {
  const AUTOMATIC_REFRESH = true;
  const REFRESH_TIME = 5 * 1000 * 60; // 5 mins

  const [refresh, setRefresh] = useState(0);

  const fetch = () => {
    processAllDataQueries(orgId);
    fetchFindings(user.id, orgId);
  };

  const retryDataQuery = (dataQueryKey) => {
    processDataQuery(orgId, dataQueryKey);
  };

  useEffect(() => {
    user && fetch();
    if (AUTOMATIC_REFRESH) {
      const interval = setInterval(() => setRefresh(refresh + 5), REFRESH_TIME);
      return () => {
        clearInterval(interval);
      };
    }
  }, [refresh, orgId, user]);

  const handleGoToFinding = ({ id }) =>
    dispatch({
      type: "PAGE",
      payload: {
        orgId: orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: id,
      },
    });

  return (
    <div className="tv-mode" data-cy={"tv-mode"}>
      <div className="action-bar row no-gutters">
        <div className="col-2">
          <img src={logo} alt="Blumira" className="logo" />
        </div>
        <div className="col-10 action-buttons">
          {!user?.monitor && (
            <Link
              to={{
                type: "PAGE",
                payload: {
                  orgId: orgId,
                  toplevel: "dashboard",
                  secondlevel: "manager",
                },
              }}
            >
              <Button className="exit">Exit Monitor Mode</Button>
            </Link>
          )}
          <Button className="logout" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>

      <Scroller
        scrollerStats={dataQueries.DASHBOARD_SCROLLER_STATS.visualizationData}
      />

      <div className="grid-wrapper">
        <div className="grid-box a">
          <div className="data-viz">
            <div className="title">Finding States</div>
            <StatesPieChart
              chartData={dataQueries.DASHBOARD_PIE_CHART}
              handleRetry={() => {
                retryDataQuery("DASHBOARD_PIE_CHART");
              }}
            />
          </div>
        </div>
        <div className="grid-box b">
          <div className="data-viz">
            <div className="title">Threats Detected</div>
            <StatesTable
              tableData={dataQueries.DASHBOARD_RECENT_THREATS_TABLE}
              onClickRow={handleGoToFinding}
              handleRetry={() => {
                retryDataQuery("DASHBOARD_RECENT_THREATS_TABLE");
              }}
            />
          </div>
        </div>
        <div className="grid-box c">
          <div className="data-viz">
            <div className="title">Current Active Findings</div>
            <ActiveFindingsTable
              tableData={dataQueries.DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE}
              compactStyle
              handleRetry={() => {
                retryDataQuery("DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE");
              }}
            />
          </div>
        </div>
        <div className="grid-box d">
          <div className="data-viz">
            <div className="title">Finding Source Locations</div>
            <Worldmap
              mapData={dataQueries.DASHBOARD_WORLD_MAP}
              handleRetry={() => {
                retryDataQuery("DASHBOARD_WORLD_MAP");
              }}
            />
          </div>
        </div>
        <div className="grid-box e">
          <div className="data-viz">
            <div className="title">Threats Detected</div>
            <TrendsGraph
              trendData={dataQueries.DASHBOARD_FINDINGS_TREND}
              graphData={dataQueries.DASHBOARD_FINDINGS_DETECTED_GRAPH}
              colorPallete={1}
              type="threats"
              handleRetry={() => {
                retryDataQuery("DASHBOARD_FINDINGS_TREND");
                retryDataQuery("DASHBOARD_FINDINGS_DETECTED_GRAPH");
              }}
            />
          </div>
        </div>
        <div className="grid-box f">
          <div className="data-viz">
            <div className="title">Finding Source Locations</div>
            <LocationsTable
              tableData={dataQueries.DASHBOARD_LOCATIONS_TABLE}
              defaultPageSize={5}
              handleRetry={() => {
                retryDataQuery("DASHBOARD_LOCATIONS_TABLE");
              }}
            />
          </div>
        </div>
        <div className="grid-box g">
          <div className="data-viz">
            <div className="title">Suspects Opened</div>
            <TrendsGraph
              trendData={dataQueries.DASHBOARD_FINDINGS_TREND}
              graphData={dataQueries.DASHBOARD_FINDINGS_DETECTED_GRAPH}
              type="suspects"
              handleRetry={() => {
                retryDataQuery("DASHBOARD_FINDINGS_TREND");
                retryDataQuery("DASHBOARD_FINDINGS_DETECTED_GRAPH");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataQueries: state.dataQuery.dataQueries,
    orgId: state.location.payload.id1
      ? state.location.payload.id1
      : state.location.payload.orgId,
    user: state.session?.settings?.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchFindings: (personId, orgId) => dispatch(fetchFindings(personId, orgId)),
  processAllDataQueries: (orgId) => dispatch(processDataQueries(orgId)),
  processDataQuery: (orgId, dataQueryKey) =>
    dispatch(processDataQueries(orgId, dataQueryKey)),
  logout: () => dispatch(logoutFromBlumira),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorModeView);

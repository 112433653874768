import React, { useState } from "react";

import {
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  IconButton,
} from "@mui/material";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import CloseIcon from "@mui/icons-material/Close";

import { StyledDialog, classes } from "./styles";
import { mspIntegrationMetadata } from "./constants";
import { MSPIntegrationLogo } from "views/Pages/MSPPortal/MSPIntegrationLogo";

const betaMSPIntegrationTooltipText =
  "Donut I love apple pie I love jelly-o I love I love lemon drops pie. Carrot cake caramels I love gummies biscuit macaroon marshmallow."; // TODO: figure out what this beta text needs to be...until then enjoy some cupcake ipsum :)

const MSPIntegrationDialog = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isInformationalTooltipOpen, setIsInformationalTooltipOpen] =
    useState(true);

  const getMSPIntegrationList = () => {
    // Only show integrations that are generally available OR the the org has the beta license feature enabled for
    return mspIntegrationMetadata.filter(
      (module) => !module.betaChip || props.license.hasFeature(module?.betaChip)
    );
  };

  const isModuleInBeta = (module) => {
    const { integrationType = "" } = module; // eslint-disable-line no-unused-vars

    return false;
  };

  const isModuleNew = (module) => {
    const { integrationType = "" } = module; // eslint-disable-line no-unused-vars

    return false;
  };

  const handleSelectModule = (module) => {
    setCurrentStep(1);
  };

  const IntegrationButton = (props) => {
    return (
      <Grid item xs={3} className={classes.buttonsGrid}>
        <Button
          variant={"outlined"}
          disabled={props.disabled}
          className={classes.moduleButton}
          datacy={props.module.integrationType}
          onClick={() => {
            // reference paidCta prop so that user can still
            // select to click on the cta link in the card
            if (props.paidCta) return;
            handleSelectModule(props.module);
          }}
        >
          <div className={classes.moduleOptionContainer}>
            <div className={classes.logoCtaContainer}>
              <div className={classes.logoContainer}>
                <MSPIntegrationLogo
                  integrationType={props.module.integrationType}
                  width={120}
                  height={66}
                />
              </div>
            </div>
            <div className={classes.textContainer}>
              <p className={classes.moduleTitleText}>{props.module.title}</p>
              <p className={classes.moduleSubtitleText}>
                {props.module.subtitle}
              </p>
            </div>
          </div>
        </Button>
        {props.paidCta ? (
          <div className={classes.paidPlanCtaContainer}>
            <p>Available on paid plans</p>
            <div>
              <a target={"_blank"} rel="noopener noreferrer" href={"#"}>
                Contact sales
                <OpenInNewIcon />
              </a>
            </div>
          </div>
        ) : props.isBeta ? (
          <Tooltip
            title={betaMSPIntegrationTooltipText}
            placement={"bottom-start"}
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
            >
              <p>Beta</p>
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        ) : props.isNew ? (
          <div
            className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
          >
            <p>New</p>
            <AutoAwesomeIcon />
          </div>
        ) : null}
      </Grid>
    );
  };

  const handleToggleTooltip = () => {
    setIsInformationalTooltipOpen(!isInformationalTooltipOpen);
  };

  const displayDialogContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Grid
            container
            spacing={2}
            className={classes.moduleOptionsContainer}
            datacy={"mspIntegrationsListModal"}
          >
            {getMSPIntegrationList().map((module, idx) => {
              return (
                <IntegrationButton
                  module={module}
                  key={module.integrationType}
                  isBeta={isModuleInBeta(module)}
                  isNew={isModuleNew(module)}
                />
              );
            })}
          </Grid>
        );
      case 1:
        return (
          <div>
            <div className={classes.titleWrap}>
              <Typography className={classes.stepOneAccordionText}>
                ConnectWise Manage One Way Syncing
              </Typography>
              <IconButton
                aria-label="Info Tooltip"
                onClick={handleToggleTooltip}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </div>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className={classes.accordionTitle}>
                    ConnectWise Manage API Connection
                    <div className={classes.accordionOneChip}>
                      <DoNotDisturbOnOutlinedIcon />
                      Not Connected
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>Content to go here</AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion className={classes.accordionContainer} disabled>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className={classes.accordionTitle}
                >
                  Account Mapping
                </AccordionSummary>
                <AccordionDetails>Content to go here</AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion className={classes.accordionContainer} disabled>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className={classes.accordionTitle}
                >
                  Service Board
                </AccordionSummary>
                <AccordionDetails>Content to go here</AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion className={classes.accordionContainer} disabled>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                  className={classes.accordionTitle}
                >
                  Blumira-to-ConnectWise Ticket Flow
                </AccordionSummary>
                <AccordionDetails>Content to go here</AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion className={classes.accordionContainer} disabled>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5-content"
                  id="panel5-header"
                  className={classes.accordionTitle}
                >
                  Verify and Enable/Disable
                </AccordionSummary>
                <AccordionDetails>Content to go here</AccordionDetails>
              </Accordion>
            </Card>
          </div>
        );
    }
  };

  const displayDialogTitle = () => {
    return currentStep === 0
      ? "Available MSP Integrations"
      : "MSP Integration Details";
  };

  const resetModal = () => {
    setTimeout(() => setCurrentStep(0), 200);
  };

  const toggleModal = () => {
    props.toggleModal();
    resetModal();
  };

  return (
    <StyledDialog
      open={props.open}
      onClose={toggleModal}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogTitle className={classes.modalTitleWrap}>
        {displayDialogTitle()}
        <IconButton onClick={toggleModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{displayDialogContent()}</DialogContent>
    </StyledDialog>
  );
};

export default MSPIntegrationDialog;

import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "IntegrationDialog";
export const classes = {
  moduleOptionsContainer: `${PREFIX}-moduleOptionsContainer`,
  moduleButton: `${PREFIX}-moduleButton`,
  moduleOptionContainer: `${PREFIX}-moduleOptionContainer`,
  textContainer: `${PREFIX}-textContainer`,
  moduleTitleText: `${PREFIX}-moduleTitleText`,
  moduleSubtitleText: `${PREFIX}-moduleSubtitleText`,
  form: `${PREFIX}-form`,
  dialogActions: `${PREFIX}-dialogActions`,
  dialogActionsBtnContainer: `${PREFIX}-dialogActionsBtnContainer`,
  alert: `${PREFIX}-alert`,
  error: `${PREFIX}-error`,
  container: `${PREFIX}-container`,
  logoCtaContainer: `${PREFIX}-logoCtaContainer`,
  logoContainer: `${PREFIX}-logoContainer`,
  paidPlanCtaContainer: `${PREFIX}-paidPlanCtaContainer`,
  nextStepContainer: `${PREFIX}-nextStepContainer`,
  nextStepCtaContainer: `${PREFIX}-nextStepCtaContainer`,
  nextStepCtaButton: `${PREFIX}-nextStepCtaButton`,
  nextStepText: `${PREFIX}-nextStepText`,
  nextStepOrText: `${PREFIX}-nextStepOrText`,
  nextStepLink: `${PREFIX}-nextStepLink`,
  buttonsGrid: `${PREFIX}-buttonsGrid`,
  tooltip: `${PREFIX}-tooltip`,
  betaTooltipCtaContainer: `${PREFIX}-betaTooltipCtaContainer`,
  accordionCard: `${PREFIX}-accordionCard`,
  accordionContainer: `${PREFIX}-accordionContainer`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  stepOneAccordionText: `${PREFIX}-stepOneAccordionText`,
  titleWrap: `${PREFIX}-titleWrap`,
  informationalTooltip: `${PREFIX}-informationalTooltip`,
  informationalTooltipText: `${PREFIX}-informationalTooltipText`,
  outlinedHeaderLink: `${PREFIX}-outlinedHeaderLink`,
  informationalTooltipClose: `${PREFIX}-informationalTooltipClose`,
  accordionOneChip: `${PREFIX}-accordionOneChip`,
  modalTitleWrap: `${PREFIX}-modalTitleWrap`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.moduleOptionsContainer}`]: {
    display: "flex",
    paddingBottom: 20,
  },
  [`& .${classes.moduleButton}`]: {
    margin: 5,
    width: "100%",
    padding: "0 !important",
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  [`& .${classes.moduleOptionContainer}`]: {
    height: 150,
    width: "100%",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    textAlign: "left",
  },
  [`& .${classes.textContainer}`]: {
    display: "flex",
    flexWrap: "no-wrap",
    flexDirection: "column",
    padding: theme.shape.padding,
  },
  [`& .${classes.moduleTitleText}`]: {
    margin: 0,
  },
  [`& .${classes.moduleSubtitleText}`]: {
    margin: 0,
    fontWeight: "bold",
  },
  [`& .${classes.form}`]: {
    width: 600,
    paddingTop: 10,
  },
  [`& .${classes.dialogActions}`]: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& a": {
      color: theme.palette.primary.main,
      "& :hover": {
        textDecoration: "underline",
      },
    },
  },
  [`& .${classes.dialogActionsBtnContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.alert}`]: {
    margin: "10px 0",
    backgroundColor: "#D9EBFF",
  },
  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.container}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.logoCtaContainer}`]: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  [`& .${classes.logoContainer}`]: {
    padding: `${theme.shape.padding}px 0 0 ${theme.shape.padding}px`,
  },
  [`& .${classes.paidPlanCtaContainer}`]: {
    fontSize: 12,
    padding: `5px 10px `,
    backgroundColor: theme.palette.info.secondary,
    borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
    position: "absolute",
    top: 21,
    right: -5,
    "& p": {
      marginBottom: 0,
      fontWeight: "bold",
      color: theme.palette.grey[900],
    },
    "& a": {
      color: theme.palette.primary.main,
      "& :hover": {
        textDecoration: "underline",
      },
    },
    "& svg": {
      paddingLeft: 5,
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.betaTooltipCtaContainer}`]: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },
  [`& .${classes.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.nextStepContainer}`]: {},
  [`& .${classes.nextStepCtaContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.shape.padding}px 0`,
  },
  [`& .${classes.nextStepCtaButton}`]: {
    height: 60,
    width: "48%",
    fontSize: 15,
  },
  [`& .${classes.nextStepText}`]: {
    textAlign: "center",
  },
  [`& .${classes.nextStepOrText}`]: {
    fontWeight: "bold",
    textAlign: "center",
    paddingTop: theme.shape.padding,
  },
  [`& .${classes.nextStepLink}`]: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
    paddingBottom: theme.shape.padding,
    "& :hover": {
      textDecoration: "underline",
    },
  },
  [`& .${classes.buttonsGrid}`]: {
    position: "relative",
  },

  [`& .${classes.accordionCard}`]: {
    boxShadow: "none",
    margin: `${theme.shape.padding}px 0`,
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
  },
  [`& .${classes.accordionContainer}`]: {
    padding: "5px 0px",
  },
  [`& .${classes.accordionTitle}`]: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  [`& .${classes.stepOneAccordionText}`]: {
    width: "fit-content",
    display: "inline-block",
    marginRight: "10px",
  },
  [`& .${classes.titleWrap}`]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
  },
  [`& .${classes.accordionOneChip}`]: {
    backgroundColor: "#FBDBB4",
    color: "#A84700",
    fontWeight: "normal",
    fontSize: 14,
    padding: "0 10px",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: 14,
      marginRight: 5,
    },
  },
  [`& .${classes.modalTitleWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import ActionDialog from "views/Components/ActionDialog";
import {
  SimpleCardContainer,
  SimpleCountInfoCard,
} from "views/Components/SimpleCountInfoCard";
import SimpleTable from "views/Components/SimpleTable";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { copyTextToClipboard } from "utils";
import API from "lib/api/API2";
import Request from "lib/api/Request";
import { getRelativeDateString } from "lib/helpers";
import { LoadStatus, useLoader } from "lib/util/LoaderHook";
import { actionFailure } from "redux/actions/Page";
import DetailsDialog, {
  DEPLOYED_STATUSES,
  ENABLED_STATUSES,
  STATUS_PENDING_DISABLE,
  STATUS_PENDING_ENABLE,
} from "./DetailsDialog";

const formatCreatedTimestamp = (_column, model) => {
  const created = model.ruleCreated;
  const relDate = getRelativeDateString(created);
  const formattedDate = moment(created).format("MMMM D, YYYY"); // "February 14, 2010"
  return `${relDate} – ${formattedDate}`;
};

const tableColumns = [
  {
    title: "Detection Rule Name",
    field: "name",
    searchable: true,
  },
  {
    title: "Findings past 24 hours",
    field: "findingsPastDay",
    searchable: false,
  },
  {
    title: "Enabled Accounts",
    field: "enabled",
    searchable: false,
  },
  {
    title: "Supported Accounts",
    field: "supported",
    searchable: false,
  },
  {
    title: "Default state",
    field: "defaultStatus",
    searchable: true,
  },
  {
    title: "Created",
    field: "ruleCreated",
    searchable: false,
    renderValue: formatCreatedTimestamp,
    sortByUnrendered: true,
  },
];

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface ConfirmationDialogProps {
//   open: boolean;
//   desiredStatus?: 50 | 101;
//   numSelected?: number;
//   numChanges?: number;
//   onCancel?: () => void;
//   onOk?: () => void;
// }

const ConfirmationDialog = (props) => {
  return (
    <ActionDialog
      open={props.open}
      title="Bulk Action"
      description={
        <span>
          {`You are ${
            props.desiredStatus === STATUS_PENDING_DISABLE
              ? "disabling"
              : "enabling"
          } ${props.numSelected} ${
            props.numSelected === 1 ? "rule" : "rules"
          } for ${props.numChanges} total ${
            props.numChanges === 1 ? "change" : "changes"
          }.`}
        </span>
      }
      actions={[
        {
          title:
            props.desiredStatus === STATUS_PENDING_DISABLE
              ? "Disable"
              : "Enable",
          action: props.onOk,
          variant: "contained",
          loading: props.processing,
          error: props.error,
        },
        {
          title: "Cancel",
          action: props.onCancel,
        },
      ]}
    />
  );
};

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface MSPBulkDetectionsTabProps {
//   orgId: string;
//   orgs: Org[];
// }

const MSPBulkDetectionsTab = (props) => {
  // confirmationProps tracks properties of the "Are You Sure" dialog
  const [confirmationProps, setConfirmationProps] = useState({
    open: false,
  });
  // detailsDialogProps tracks properties of the "Rule Details" dialog
  const [detailsDialogProps, setDetailsDialogProps] = useState({
    open: false,
  });
  const [ruleFiltersDropdownVal, setRuleFiltersDropdownVal] = useState("all");
  const dispatch = useDispatch();

  const orgIds = useMemo(() => props.orgs.map((org) => org.id), [props.orgs]);

  const basisRulesLoadStatus = useLoader(
    () => {
      // Load basis rules
      const queryParams = [
        { field: "orgId", value: null },
        { field: "isBasis", value: true },
      ];
      const resultParams = {
        outputFields: ["id", "name", "conditionSet", "created"],
      };
      const request = new Request("/rule", queryParams, resultParams);
      return request.get();
    },
    "MSPBulkDetectionsTab-basisRules",
    [props.orgId]
  );

  const basisConditionsLoadStatus = useLoader(
    () => {
      // Load basis conditions
      const queryParams = [{ field: "orgId", value: null }];
      const resultParams = {
        outputFields: [
          "id",
          "analysis",
          "method",
          "targetAction",
          "tags",
          "created",
        ],
      };
      const request = new Request("/condition", queryParams, resultParams);
      return request.get();
    },
    "MSPBulkDetectionsTab-basisConditions",
    [props.orgId]
  );

  const ruleStatusesLoadStatus = useLoader(
    () => {
      // Load counts of basis rule statuses
      const queryParams = [
        {
          field: "orgId",
          operator: "in",
          value: orgIds,
        },
        {
          field: "status",
          operator: "in",
          negate: false,
          value: DEPLOYED_STATUSES,
        },
      ];
      const resultParams = {
        outputFields: ["originatingRule", "status"],
        transform: [
          {
            operator: "count",
            value: "count",
            field: "",
            negate: false,
          },
        ],
      };
      const request = new Request("/rule", queryParams, resultParams);
      return request.get();
    },
    "MSPBulkDetectionsTab-ruleStatuses",
    [orgIds]
  );

  const conditionOverridesLoadStatus = useLoader(
    () => {
      // Load any status overrides this org has
      const request = new Request("/conditionoverride");
      return request.get();
    },
    "MSPBulkDetectionsTab-conditionOverrides",
    [props.orgId]
  );

  const allowListsLoadStatus = useLoader(
    () => {
      // Load counts of allow lists
      const queryParams = [
        {
          field: "orgId",
          operator: "in",
          value: orgIds,
        },
      ];
      const resultParams = {
        outputFields: [],
        transform: [
          {
            operator: "count",
            value: "count",
            field: "",
            negate: false,
          },
        ],
      };
      const request = new Request("/allowlistentry", queryParams, resultParams);
      return request.get();
    },
    "MSPBulkDetectionsTab-allowLists",
    [orgIds]
  );

  const findingCountsLoadStatus = useLoader(
    () => {
      // Load counts of findings
      const findingsSince = moment().subtract(24, "hours").utc().format();
      const queryParams = [
        { field: "orgId", operator: "in", value: orgIds },
        { field: "findingId", negate: true, value: null },
        { field: "created", operator: "gt", value: findingsSince },
      ];
      const resultParams = {
        outputFields: ["basisRuleId"],
        transform: [
          {
            operator: "count",
            value: "count",
            field: "",
            negate: false,
          },
        ],
      };
      const request = new Request("/match", queryParams, resultParams);
      return request.get();
    },
    "MSPBulkDetectionsTab-findingCounts",
    [orgIds]
  );

  const actionsLoadStatus = useLoader(
    () => {
      // Load all actions
      const request = new Request("/action");
      return request.get();
    },
    "MSPBulkDetectionsTab-actions",
    []
  );

  const findingTypesLoadStatus = useLoader(
    () => {
      // Load all actions
      const request = new Request("/constant/finding_type");
      return request.get();
    },
    "MSPBulkDetectionsTab-findingTypes",
    []
  );

  const ruleCountsLoadStatus = useMemo(() => {
    // Compute rule counts
    const ruleStatuses = ruleStatusesLoadStatus.data;
    if (ruleStatuses === undefined) {
      // Still loading, or got error.  Set ruleCounts to the same status.
      return ruleStatusesLoadStatus;
    }
    const countsByRule = {};
    ruleStatuses.forEach((rs) => {
      if (!(rs.originatingRule in countsByRule)) {
        countsByRule[rs.originatingRule] = { enabledCount: 0, count: 0 };
      }
      if (ENABLED_STATUSES.includes(rs.status)) {
        countsByRule[rs.originatingRule].enabledCount += rs.count;
      }
      countsByRule[rs.originatingRule].count += rs.count;
    });
    return new LoadStatus({
      data: { countsByRule },
    });
  }, [ruleStatusesLoadStatus]);

  const detectionsLoadStatus = useMemo(() => {
    // Compute list of detections
    const basisRules = basisRulesLoadStatus.data;
    if (basisRules === undefined) {
      // Still loading, or got error.  Set detections to the same status.
      return basisRulesLoadStatus;
    }
    const basisConditions = basisConditionsLoadStatus.data;
    if (basisConditions === undefined) {
      // Still loading, or got error.  Set detections to the same status.
      return basisConditionsLoadStatus;
    }
    const conditionOverrides = conditionOverridesLoadStatus.data;
    if (conditionOverrides === undefined) {
      // Still loading, or got error.  Set detections to the same status.
      return conditionOverridesLoadStatus;
    }
    if (ruleCountsLoadStatus.data === undefined) {
      // Not computed yet.  Set detections to the same status.
      return ruleCountsLoadStatus;
    }
    const ruleCounts = ruleCountsLoadStatus.data.countsByRule;
    const findingCounts = findingCountsLoadStatus.data;
    if (findingCounts === undefined) {
      // Still loading, or got error.  Set detections to the same status.
      return findingCountsLoadStatus;
    }
    const conditions = {};
    basisConditions.forEach((cond) => {
      conditions[cond.id] = cond;
    });
    const overrides = {};
    conditionOverrides.forEach((override) => {
      overrides[override.conditionId] = override;
    });
    const findingCountsByRule = {};
    findingCounts.forEach((finding) => {
      findingCountsByRule[finding.basisRuleId] = finding.count;
    });
    const tableData = [];
    const now = moment();
    let numSupported = 0;
    let numEnabled = 0;
    let numFindings = 0;
    let numCreatedPastMonth = 0;
    basisRules.forEach((rule) => {
      rule.conditionSet.forEach((condref) => {
        if (conditions[condref.condition]) {
          const condition = conditions[condref.condition];
          const findingsPastDay = findingCountsByRule[rule.id] || 0;
          const isDeprecated = condition.tags.includes("status:deprecated");
          const enabled = ruleCounts[rule.id]?.enabledCount || 0;
          // For deprecated conditions, we only support enabled rules.
          // Once they're disabled, they can't be enabled again.
          const supported = isDeprecated
            ? enabled
            : ruleCounts[rule.id]?.count || 0;
          const ruleCreated =
            rule.created > condition.created ? rule.created : condition.created;
          const blumiraDefaultDisabled = condition.tags.includes(
            "status:default_disabled"
          );
          const override = overrides[condition.id];
          const isDefaultDisabled = override
            ? override.defaultDeployStatus === STATUS_PENDING_DISABLE
            : blumiraDefaultDisabled;
          const isRecent = now.diff(moment(ruleCreated), "months", true) <= 1;

          // For deprecated conditions, display them so that users have an
          // opportunity to bulk-disable them.
          // Once they are disabled, hide the rule.
          const shouldDisplay = !isDeprecated || enabled > 0;
          if (shouldDisplay) {
            tableData.push({
              id: rule.id,
              name: rule.name,
              findingsPastDay,
              enabled,
              supported,
              defaultStatus:
                (isDefaultDisabled ? "Disabled" : "Enabled") +
                (override ? " (custom)" : ""),
              ruleCreated,
              rule,
              condition,
              blumiraDefaultDisabled,
              override,
              isDefaultDisabled,
              isRecent,
            });
            if (isRecent) {
              numCreatedPastMonth += 1;
            }
            numSupported += supported;
            numEnabled += enabled;
            numFindings += findingsPastDay;
          }
        }
      });
    });
    return new LoadStatus({
      data: {
        tableData,
        numFindings,
        numEnabled,
        numSupported,
        numCreatedPastMonth,
      },
    });
  }, [
    basisRulesLoadStatus,
    basisConditionsLoadStatus,
    ruleCountsLoadStatus,
    conditionOverridesLoadStatus,
    findingCountsLoadStatus,
  ]);

  const filteredTableDataLoadStatus = useMemo(() => {
    if (detectionsLoadStatus.data === undefined) {
      // Still loading, or got error.  Set ruleCounts to the same status.
      return detectionsLoadStatus;
    }
    const unfilteredDetections = detectionsLoadStatus.data.tableData;

    let filteredDetections;
    switch (ruleFiltersDropdownVal) {
      case "hasFindings":
        filteredDetections = unfilteredDetections.filter(
          (r) => r.findingsPastDay > 0
        );
        break;
      case "recent":
        filteredDetections = unfilteredDetections.filter((r) => r.isRecent);
        break;
      case "noCustom":
        filteredDetections = unfilteredDetections.filter((r) => !r.override);
        break;
      case "custom":
        filteredDetections = unfilteredDetections.filter((r) => !!r.override);
        break;
      case "defaultEnabled":
        filteredDetections = unfilteredDetections.filter(
          (r) => !r.isDefaultDisabled
        );
        break;
      case "defaultDisabled":
        filteredDetections = unfilteredDetections.filter(
          (r) => r.isDefaultDisabled
        );
        break;
      case "enabled":
        filteredDetections = unfilteredDetections.filter((r) => r.enabled > 0);
        break;
      case "disabled":
        filteredDetections = unfilteredDetections.filter(
          (r) => r.enabled < r.supported
        );
        break;
      case "different":
        filteredDetections = unfilteredDetections.filter(
          (r) => r.enabled !== (r.isDefaultDisabled ? 0 : r.supported)
        );
        break;
      default:
        filteredDetections = unfilteredDetections;
        break;
    }
    return new LoadStatus({ data: filteredDetections });
  }, [detectionsLoadStatus, ruleFiltersDropdownVal]);

  // Find table row to use in the details dialog.
  // Note: we can't store the model in detailsDialogProps because the model
  // will be recomputed when conditionOverrides are reloaded.
  const tableRowModel = useMemo(
    () =>
      detectionsLoadStatus.data?.tableData?.find(
        (row) => row.id === detailsDialogProps.ruleId
      ),
    [detectionsLoadStatus, detailsDialogProps]
  );

  const showConfirmation = useCallback(
    ({ desiredStatus, numSelected, numChanges, onOk, error }) => {
      setConfirmationProps({
        open: true,
        processing: false,
        desiredStatus,
        numSelected,
        numChanges,
        error,
        onCancel: () =>
          setConfirmationProps((prevState) => ({
            // Even though we're closing the dialog, we need to preserve
            // the previous state so that the closing animation is smooth.
            ...prevState,
            open: false,
          })),
        onOk: async () => {
          setConfirmationProps((prevState) => ({
            ...prevState,
            processing: true,
          }));
          try {
            await onOk();
            setConfirmationProps((prevState) => ({
              // Even though we're closing the dialog, we need to preserve
              // the previous state so that the closing animation is smooth.
              ...prevState,
              open: false,
            }));
          } catch (error2) {
            dispatch(actionFailure(error2));
            setConfirmationProps((prevState) => ({
              ...prevState,
              processing: false,
              error: error2,
            }));
          }
        },
      });
    },
    [dispatch]
  );

  // When this is called, the ruleStatuses will be reloaded
  const refreshRuleStatuses = useCallback(() => {
    return ruleStatusesLoadStatus.reload?.({ reset: false });
  }, [ruleStatusesLoadStatus]);

  const refreshConditionOverrides = useCallback(() => {
    return conditionOverridesLoadStatus.reload?.({ reset: false });
  }, [conditionOverridesLoadStatus]);

  const handleMultiruleChange = useCallback(
    async (ruleIds, numChanges, desiredStatus) => {
      showConfirmation({
        desiredStatus,
        numSelected: ruleIds.length,
        numChanges,
        onOk: async () => {
          const params = {
            orgIds,
            basisRuleIds: ruleIds,
            desiredStatus,
          };
          const api = new API();
          await api.rpc("/updateRuleStatuses", params);
          await refreshRuleStatuses();
        },
      });
    },
    [showConfirmation, orgIds, refreshRuleStatuses]
  );

  // Function that returns Enable/Disable buttons when you select checkboxes
  const renderMultiselectActions = useCallback(
    (selectedIds, allData) => {
      const dataById = {};
      allData.forEach((model) => {
        dataById[model.id] = model;
      });
      const idsCanEnable = [];
      const idsCanDisable = [];
      let numChangesEnabling = 0;
      let numChangesDisabling = 0;
      selectedIds.forEach((id) => {
        if (!dataById[id]) {
          return;
        }
        if (dataById[id].supported > dataById[id].enabled) {
          numChangesEnabling += dataById[id].supported - dataById[id].enabled;
          idsCanEnable.push(id);
        }
        if (dataById[id].enabled > 0) {
          numChangesDisabling += dataById[id].enabled;
          idsCanDisable.push(id);
        }
      });
      const handleEnable = () => {
        handleMultiruleChange(
          idsCanEnable,
          numChangesEnabling,
          STATUS_PENDING_ENABLE
        );
      };
      const handleDisable = () => {
        handleMultiruleChange(
          idsCanDisable,
          numChangesDisabling,
          STATUS_PENDING_DISABLE
        );
      };
      return (
        <>
          <Tooltip
            title={
              idsCanEnable.length === 0 &&
              "All supported rules are already enabled"
            }
          >
            <span>
              <Button
                className="SimpleTable-toolbarActionButton"
                onClick={handleEnable}
                disabled={idsCanEnable.length === 0}
              >
                Enable
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={idsCanDisable.length === 0 && "No rules are enabled"}>
            <span>
              <Button
                className="SimpleTable-toolbarActionButton"
                onClick={handleDisable}
                disabled={idsCanDisable.length === 0}
              >
                Disable
              </Button>
            </span>
          </Tooltip>
        </>
      );
    },
    [handleMultiruleChange]
  );

  // Context Menu Fucntions
  const handleMenuCopy = useCallback((contextMenu) => {
    copyTextToClipboard(contextMenu.anchorEl.innerText);
  }, []);

  const handleViewDetails = useCallback((contextMenu) => {
    // Use setTimeout to prevent background behind the dialog from scrolling.
    // The zero timeout ensures that the contextMenu closes and restores the
    // scrolling before the dialog opens and disables the scrolling.
    setTimeout(() => {
      setDetailsDialogProps({
        open: true,
        ruleId: contextMenu.model.id,
        tabValue: "Details",
      });
    }, 0);
  }, []);

  const handleViewSupportedAccounts = useCallback((contextMenu) => {
    // Use setTimeout to prevent background behind the dialog from scrolling.
    // The zero timeout ensures that the contextMenu closes and restores the
    // scrolling before the dialog opens and disables the scrolling.
    setTimeout(() => {
      setDetailsDialogProps({
        open: true,
        ruleId: contextMenu.model.id,
        tabValue: "Accounts",
        showUnsupported: false,
      });
    }, 0);
  }, []);

  const handleViewUnsupportedAccounts = useCallback((contextMenu) => {
    // Use setTimeout to prevent background behind the dialog from scrolling.
    // The zero timeout ensures that the contextMenu closes and restores the
    // scrolling before the dialog opens and disables the scrolling.
    setTimeout(() => {
      setDetailsDialogProps({
        open: true,
        ruleId: contextMenu.model.id,
        tabValue: "Accounts",
        showUnsupported: true,
      });
    }, 0);
  }, []);

  return (
    <>
      <ConfirmationDialog {...confirmationProps} />
      <DetailsDialog
        {...detailsDialogProps}
        tableRowModel={tableRowModel}
        setDetailsDialogProps={setDetailsDialogProps}
        actionsLoadStatus={actionsLoadStatus}
        findingTypesLoadStatus={findingTypesLoadStatus}
        orgId={props.orgId}
        orgs={props.orgs}
        orgIds={orgIds}
        refreshConditionOverrides={refreshConditionOverrides}
        refreshRuleStatuses={refreshRuleStatuses}
      />
      <SimpleCardContainer>
        <SimpleCountInfoCard
          widthPercent={20}
          header={"Findings past 24 hours"}
          tooltip="The combined total of all created findings across all sub-accounts"
          countToDisplay={detectionsLoadStatus.data?.numFindings}
          isLoading={detectionsLoadStatus.loading}
          error={detectionsLoadStatus.error}
        />
        <SimpleCountInfoCard
          widthPercent={20}
          header={"Enabled rules"}
          tooltip="The combined total of all detection rules across all sub-accounts that are currently enabled"
          countToDisplay={detectionsLoadStatus.data?.numEnabled}
          isLoading={detectionsLoadStatus.loading}
          error={detectionsLoadStatus.error}
        />
        <SimpleCountInfoCard
          widthPercent={20}
          header={"Supported rules"}
          tooltip="The combined total of all detection rules across all supported sub-accounts, regardless of state"
          countToDisplay={detectionsLoadStatus.data?.numSupported}
          isLoading={detectionsLoadStatus.loading}
          error={detectionsLoadStatus.error}
        />
        <SimpleCountInfoCard
          widthPercent={20}
          header={"Total detection filters"}
          tooltip="The combined total of all detection filters across all sub-accounts and all rules"
          countToDisplay={allowListsLoadStatus.data?.[0]?.count}
          isLoading={allowListsLoadStatus.loading}
          error={allowListsLoadStatus.error}
        />
        <SimpleCountInfoCard
          widthPercent={20}
          header={"Rules created past month"}
          tooltip={
            <>
              {"The number of all new released detections over the past month."}
              <br />
              {
                "You can also find more information in our Detection Update blog posts."
              }
            </>
          }
          countToDisplay={detectionsLoadStatus.data?.numCreatedPastMonth}
          isLoading={detectionsLoadStatus.loading}
          error={detectionsLoadStatus.error}
        />
      </SimpleCardContainer>
      <div data-testid="rules-table">
        <SimpleTable
          isNorthStar
          data={filteredTableDataLoadStatus.data}
          columns={tableColumns}
          initialOrderBy={["findingsPastDay", "desc"]}
          isFetching={filteredTableDataLoadStatus.loading}
          emptyText={
            filteredTableDataLoadStatus.error ? (
              <span>Failed to load detection rules</span>
            ) : (
              <span>No detection rules available</span>
            )
          }
          renderMultiselectActions={renderMultiselectActions}
          contextMenuItems={[
            { onClick: handleViewDetails, text: "Rule Details" },
            {
              onClick: handleViewSupportedAccounts,
              text: "Supported Accounts",
            },
            {
              onClick: handleViewUnsupportedAccounts,
              text: "Unsupported Accounts",
            },
            { onClick: handleMenuCopy, text: "Copy to Clipboard" },
          ]}
          toolbarActions={[
            {
              isPrimary: true,
              component: (
                <FormControl style={{ marginRight: 10 }}>
                  <InputLabel id={"rules-select-label"}>
                    Search preset
                  </InputLabel>
                  <Select
                    id={"rules-select"}
                    label="Search preset"
                    value={ruleFiltersDropdownVal}
                    labelId={"rules-select-label"}
                    onChange={(event) => {
                      setRuleFiltersDropdownVal(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="all" datacy={"allRulesButton"}>
                      All detection rules
                    </MenuItem>
                    <MenuItem value="hasFindings">
                      Findings past 24 hours
                    </MenuItem>
                    <MenuItem value="recent">Created past month</MenuItem>
                    <MenuItem value="noCustom">Blumira default state</MenuItem>
                    <MenuItem value="custom">Custom default state</MenuItem>
                    <MenuItem value="defaultEnabled">Default enabled</MenuItem>
                    <MenuItem value="defaultDisabled">
                      Default disabled
                    </MenuItem>
                    <MenuItem value="enabled">Enabled for Account</MenuItem>
                    <MenuItem value="disabled">Disabled for Account</MenuItem>
                    <MenuItem value="different">
                      Different from default state
                    </MenuItem>
                  </Select>
                </FormControl>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default MSPBulkDetectionsTab;

import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { Alert } from "@mui/material";

import {
  CircularProgress,
  Card,
  Button,
  TextField,
  DialogContent,
} from "@mui/material";

import {
  Root,
  SignUpDialog,
  rootClasses,
  dialogClasses,
} from "./loginViewStyles";

import LoginButton from "./LoginButton";
import SignupButton from "./SignupButton";

import { loginToBlumira } from "../../redux/actions/Page";

import { isValidHostname } from "utils";

import logo from "../../images/logo_simple.svg";

export const formatAuthResultForLogin = (claimsFromAuth0) => {
  // if you need the raw id_token, you can access it
  // using the __raw property
  const idToken = claimsFromAuth0.__raw;

  const authResultToReturn = {
    idToken: idToken,
    idTokenPayload: claimsFromAuth0,
  };

  return authResultToReturn;
};

const LoginView = (props) => {
  const { isAuthenticated, getIdTokenClaims, logout } = useAuth0();

  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);

  const [orgNameInState, setOrgNameInState] = useState("");
  const [orgWebUrlInState, setOrgWebUrlInState] = useState("");
  const [referralSourceInState, setReferralSourceInState] = useState("");

  // universal login actions such as password reset build an active Auth0 session for a user,
  // which then inhibits them from interacting with the unverisal login as expected.
  // In these cases we should delete their session so they can continue as expected.
  useEffect(() => {
    if (props.logoutUserFromExistingSession) {
      setIsComponentLoading(true);
      try {
        logout({ logoutParams: { returnTo: window.location.origin } });
      } catch (e) {
        setIsComponentLoading(false);
      }
    }
  }, [props.logoutUserFromExistingSession]);

  // handle errors here, i.e. tunnel breaks and instead of displaying a loading
  // sign indefinitely let's reset the state of the component and show the error
  useEffect(() => {
    if (props.error) {
      setError(props.error);
      setIsComponentLoading(false);
      localStorage.removeItem("isLoggingIn");
    }
  }, [props.error]);

  useEffect(() => {
    // if a valid redirect payload is present, set it in local storage
    if (props.redirectPayload?.orgId) {
      localStorage.setItem(
        "redirect-payload",
        JSON.stringify(props.redirectPayload)
      );
    }
  }, [props.redirectPayload]);

  useEffect(() => {
    const isSigningUp = localStorage.getItem("isSigningUp");

    const fetchIdTokenClaimsForAuthResult = async () => {
      const claims = await getIdTokenClaims();
      const authResult = formatAuthResultForLogin(claims);
      return authResult;
    };

    const fetchIdTokenClaimsAndLogin = async () => {
      // TODO: handle this scopeSetUser func for sentry
      // scopeSetUser({ userId })

      try {
        setIsComponentLoading(true);
        setLoadingMessage("Logging you in...");

        const authResult = await fetchIdTokenClaimsForAuthResult();

        let loginDispatchObject = { authResult };

        const redirectPayload = localStorage.getItem("redirect-payload");

        // if we have a redirect payload, use it
        if (
          JSON.parse(redirectPayload) &&
          Object.keys(JSON.parse(redirectPayload)).length
        ) {
          loginDispatchObject.redirectPayload = JSON.parse(redirectPayload);
        }

        // isSigningUp is stored in local storage and set when a user
        // interacts with the sign up button
        loginDispatchObject.createPersonIfNotExists =
          isSigningUp && isSigningUp === "true";

        props.dispatchLoginToBlumira(loginDispatchObject);

        // clear out redirect payload for next time
        localStorage.setItem("redirect-payload", null);
      } catch (e) {
        setIsComponentLoading(false);
        localStorage.removeItem("isLoggingin");
        setError("Oops, we had trouble logging you in.");
      }
    };

    const handleLogout = ({ message = "", time = 2000 }) => {
      setIsComponentLoading(true);
      setLoadingMessage(message);
      setIsButtonDisabled(false);
      setTimeout(() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      }, time);
    };

    // if logging out, log out of Auth0
    if (props.logout) {
      handleLogout({ message: "Logging you out..." });
    }

    if (props.logout) {
      handleLogout({ message: "Logging you out..." });
    }

    // if user is authenticated by auth0 and the user is not
    // logging out and the user is not returning from email confirmation
    // in which cases we have informational messages to display
    if (isAuthenticated && !props.logout && !props.isEmailConfirmed) {
      fetchIdTokenClaimsAndLogin();
    }

    if (!isAuthenticated) {
      localStorage.removeItem("isLoggingIn");
    }
  }, [isAuthenticated, props.logout, props.isEmailConfirmed]);

  const toggleSignupDialog = () => {
    // if this person is closing/opening the dialog
    // reset values in local storage and in state
    setError("");
    setOrgNameInState("");
    setOrgWebUrlInState("");
    setReferralSourceInState("");
    setIsSignUpDialogOpen(!isSignUpDialogOpen);
  };

  const handleSetOrgVarsInLocalStorage = () => {
    localStorage.setItem("orgName", orgNameInState);
    localStorage.setItem("orgWebUrl", orgWebUrlInState);
    localStorage.setItem("referralSource", referralSourceInState);
  };

  const handleChangeOrgName = (event) => {
    setOrgNameInState(event.target.value);
  };

  const handleChangeOrgWebUrl = (event) => {
    if (event?.target?.value) {
      const isUrlValid = isValidHostname(event.target.value);
      if (!isUrlValid) {
        setError(
          "Please enter a valid domain in the format 'example.com' or 'subdomain.example.com'."
        );
      } else {
        setError("");
      }
    } else {
      setError("");
    }
    setOrgWebUrlInState(event.target.value);
  };

  const handleChangeReferralSource = (event) => {
    setReferralSourceInState(event.target.value);
  };

  const isNextButtonDisabled = () => {
    return (
      !orgNameInState || !orgWebUrlInState || !isValidHostname(orgWebUrlInState)
    );
  };

  const showLoading = () => {
    const isSigningUpVar = localStorage.getItem("isLoggingIn");
    return isSigningUpVar === "true" || isComponentLoading;
  };

  return (
    <Root className={rootClasses.root}>
      {!isSignUpDialogOpen && (
        <Card className={rootClasses.loginCard}>
          <img className={rootClasses.logo} src={logo} alt="Logo" />
          {showLoading() ? (
            <div className={rootClasses.loadingContainer}>
              <p className={rootClasses.loadingText}>{loadingMessage}</p>
              <CircularProgress className={rootClasses.circularProgress} />
            </div>
          ) : (
            <>
              <h2>Welcome</h2>
              {/* display any error messages */}
              {error && (
                <Alert severity={"error"} className={rootClasses.alert}>
                  {error}
                </Alert>
              )}

              {/* the display messages will be taken from the query in the url provided by auth0 */}
              {!!localStorage.getItem("displayMessage") && (
                <Alert severity={"info"} className={rootClasses.alert}>
                  {localStorage.getItem("displayMessage")}
                </Alert>
              )}

              <div className={rootClasses.textContainer}>
                <p>
                  If your organization has already signed up, please ask your
                  Blumira admin to invite you through the Blumira App (Settings
                  > Users). Then follow the instructions in the invite email to
                  set up your account.
                </p>
              </div>

              <div className={rootClasses.buttonContainer}>
                <LoginButton
                  userEmail={props.userEmail}
                  disabled={isComponentLoading || isButtonDisabled}
                />
                <Button
                  className={rootClasses.primaryButton}
                  onClick={() => toggleSignupDialog()}
                >
                  Sign up as a new organization
                </Button>
              </div>
            </>
          )}
        </Card>
      )}
      <SignUpDialog
        open={isSignUpDialogOpen}
        BackdropProps={{ invisible: true }}
      >
        <DialogContent className={dialogClasses.dialogContent}>
          <img className={dialogClasses.logo} src={logo} alt="Logo" />
          <h1 className={dialogClasses.dialogTitle}>Welcome</h1>
          <p className={dialogClasses.dialogDescText}>
            Create a Blumira account for your organization below to start using
            Blumira's Free Edition
          </p>
          {error && (
            <Alert severity={"warning"} className={dialogClasses.dialogAlert}>
              {error}
            </Alert>
          )}
          <TextField
            onChange={handleChangeOrgName}
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            placeholder={"Organization name"}
            className={dialogClasses.auth0InspiredTextField}
          />
          <TextField
            onChange={handleChangeOrgWebUrl}
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            placeholder={"Organization domain"}
            className={dialogClasses.auth0InspiredTextField}
          />
          <TextField
            onChange={handleChangeReferralSource}
            InputLabelProps={{ shrink: true }}
            InputProps={{ disableUnderline: true }}
            placeholder={"How did you hear about us?"}
            className={dialogClasses.auth0InspiredTextField}
          />
          {isNextButtonDisabled() && (
            <p className={dialogClasses.formHelperText}>
              An organization name and domain in the format 'example.com' or
              'subdomain.example.com' are required
            </p>
          )}
          <div className={dialogClasses.auth0ButtonContainer}>
            <SignupButton
              disabled={isNextButtonDisabled()}
              setOrgVarsInLocalStorage={handleSetOrgVarsInLocalStorage}
            />{" "}
            {/* this is the "Next" button that opens Auth0-hosted used signup */}
            <Button
              className={dialogClasses.auth0InspiredTextButton}
              variant={"text"}
              onClick={toggleSignupDialog}
            >
              Back
            </Button>
          </div>
        </DialogContent>
      </SignUpDialog>
    </Root>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.global;
  const { query = {} } = state.location;

  let userEmail = "";
  let displayMessage = "";
  let isEmailConfirmed = false;
  let logoutUserFromExistingSession = false;

  // if auth0 returns an access denied error there is likely
  // action for the user to take and we should display it
  // i.e. the user needs to validate their email
  // or needs to reset their password
  if (query.error && query.error_description) {
    // use Auth0 provided text
    displayMessage = query.error_description;

    localStorage.setItem("displayMessage", displayMessage);

    // universal login actions such as password reset technically build a session for a user,
    // which then inhibits them from interacting with the unverisal login as expected.
    logoutUserFromExistingSession = true;
  }

  // if this user is returning to the login page after confirming
  // their email, we should reflect this in the UI
  if (query.code === "success" || query.success === "true") {
    // the presence of supportSignUp and a success code from auth0
    // let's us know this user has returned from confirming their email
    if (query.supportSignUp === "true") isEmailConfirmed = true;

    // auth0 will provide us with the user's email so we can prefill text inputs
    userEmail = query.email;
  }

  // the query message from auth0 will tell the user useful information
  // i.e. if their email was verified or if the url is expired
  if (query.message) {
    displayMessage = query.message;
    localStorage.setItem("displayMessage", displayMessage);
  }

  const redirectPayload = state.location?.payload?.orgId
    ? state.location?.payload
    : {};

  // if we do not have any helpful information to display
  // and detect an error, display it
  let errorToDisplay = "";
  if (!displayMessage) {
    errorToDisplay = error
      ? `Oops, we had trouble with that request. Details: ${error}`
      : query.error;
  }

  return {
    userEmail,
    redirectPayload,
    isEmailConfirmed,
    error: errorToDisplay,
    logout: !!query.logout,
    logoutUserFromExistingSession,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchLoginToBlumira: ({
    authResult,
    redirectPayload,
    createPersonIfNotExists,
  }) =>
    dispatch(
      loginToBlumira({ authResult, redirectPayload, createPersonIfNotExists })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);

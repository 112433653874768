import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import _ from "lodash";
import Link from "redux-first-router-link";

import { connect } from "react-redux";

import PageHeader from "../Components/PageHeader";
import Scroller from "./Dashboard/Scroller";
import StatesPieChart from "./Dashboard/StatesPieChart";
import StatesTable from "./Dashboard/StatesTable";
import ActiveFindingsTable from "./Dashboard/ActiveFindingsTable";
import WorldMap from "./Dashboard/Worldmap";
import LocationsTable from "./Dashboard/LocationsTable";
import TrendsGraph from "./Dashboard/TrendsGraph";

import * as Actions from "../../redux/actions";

import Loading from "../Components/Loading";
import NewsStory from "../Components/NewsStory";
import ActivityStory from "../Components/ActivityStory";

import Collapse from "../Components/Collapse";

class DashboardPageView extends Component {
  constructor(props) {
    super(props);

    this.fetch = this.fetch.bind(this);
    this.retryDataQuery = this.retryDataQuery.bind(this);
    this.update = this.update.bind(this);
    this.renderNews = this.renderNews.bind(this);
    this.renderAnalystResponderTrending =
      this.renderAnalystResponderTrending.bind(this);
    this.renderManagerTrending = this.renderManagerTrending.bind(this);
    this.renderActivityFeed = this.renderActivityFeed.bind(this);
    this.renderTrending = this.renderTrending.bind(this);
    this.handleGoToFinding = this.handleGoToFinding.bind(this);
  }

  componentDidUpdate(prevProps) {
    // if there's a change in org, load new data
    if (prevProps.orgId && prevProps.orgId !== this.props.orgId) this.fetch();
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    const { dispatch, orgId, user } = this.props;
    this.setState({ isFetching: true });
    dispatch(Actions.DataQuery.processDataQueries(orgId));
    dispatch(
      Actions.Findings.fetchFindings({
        personId: user.id,
        orgId,
        withoutStatus: 40,
      })
    );
    this.setState({ isFetching: false });
  }

  retryDataQuery(dataQueryKey) {
    const { dispatch, orgId } = this.props;
    dispatch(Actions.DataQuery.processDataQueries(orgId, dataQueryKey));
  }

  update(data = {}) {
    const { findings = [] } = this.props.data || {};
    const newEvents = findings.slice();
    _.each(newEvents, (e, i) => {
      if (e.id === data.id) {
        newEvents[i] = data;
      }
    });
    // @TODO: make api request to update user in the db
    // dispatch({ type: 'PAGE_DATA_PART', part: 'findings', data: newEvents });
  }

  viewTimeline(finding) {
    const { routeToPage, orgId } = this.props;
    routeToPage({
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "threats",
        id1: finding.id,
      },
    });
  }

  handleGoToFinding({ id }) {
    const { dispatch, orgId } = this.props;
    dispatch({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: id,
      },
    });
  }

  renderNews(news, user) {
    return (
      <React.Fragment>
        <div className="column-header">
          <div className="uppercase">News</div>
        </div>
        <div className="column-content padding16">
          {_.map(news || [], (n) => (
            <NewsStory user={user} key={n.id} {...n} />
          ))}
        </div>
      </React.Fragment>
    );
  }

  // @TODO: news and activity are potentially getting phased out
  renderActivityFeed(activity) {
    return (
      <React.Fragment>
        <div className="column-header" style={{ marginTop: "28px" }}>
          <div className="uppercase">Activity Feed</div>
        </div>
        <div className="column-content padding16">
          {_.map(activity || [], (n) => (
            <ActivityStory key={n.id} {...n} />
          ))}
        </div>
      </React.Fragment>
    );
  }

  renderAnalystResponderTrending(dataQueries) {
    return (
      <div className="column-content">
        <Collapse title="Finding States" height="100%">
          <StatesPieChart chartData={dataQueries.DASHBOARD_PIE_CHART} />
        </Collapse>
        <Collapse title="Finding Source Locations" height="100%">
          <WorldMap mapData={dataQueries.DASHBOARD_WORLD_MAP} />
        </Collapse>
      </div>
    );
  }

  renderManagerTrending(data, reports, dataQueries) {
    return (
      <React.Fragment>
        <div className="column-content">
          <Collapse
            collapseId="dashboard-5"
            title="Threats Detected"
            height="100%"
          >
            <div className="chart-table-container">
              <div className="child-chart">
                <TrendsGraph
                  trendData={dataQueries.DASHBOARD_FINDINGS_TREND}
                  graphData={dataQueries.DASHBOARD_FINDINGS_DETECTED_GRAPH}
                  type="threats"
                  colorPallete={1}
                  handleRetry={() => {
                    this.retryDataQuery("DASHBOARD_FINDINGS_TREND");
                    this.retryDataQuery("DASHBOARD_FINDINGS_DETECTED_GRAPH");
                  }}
                />
              </div>
              <div className="child-table">
                <StatesTable
                  tableData={dataQueries.DASHBOARD_RECENT_THREATS_TABLE}
                  onClickRow={this.handleGoToFinding}
                  handleRetry={() => {
                    this.retryDataQuery("DASHBOARD_RECENT_THREATS_TABLE");
                  }}
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className="column-content">
          <Collapse
            collapseId="dashboard-6"
            title="Suspects Opened"
            height="100%"
          >
            <div className="chart-table-container">
              <div className="child-chart">
                <TrendsGraph
                  trendData={dataQueries.DASHBOARD_FINDINGS_TREND}
                  graphData={dataQueries.DASHBOARD_FINDINGS_DETECTED_GRAPH}
                  type="suspects"
                  handleRetry={() => {
                    this.retryDataQuery("DASHBOARD_FINDINGS_TREND");
                    this.retryDataQuery("DASHBOARD_FINDINGS_DETECTED_GRAPH");
                  }}
                />
              </div>
            </div>
          </Collapse>
          <div className="column-content">
            <Collapse
              collapseId="dashboard-7"
              title="Finding Source Locations"
              height="100%"
            >
              <div className="chart-table-container">
                <div className="child-chart">
                  <WorldMap
                    mapData={dataQueries.DASHBOARD_WORLD_MAP}
                    handleRetry={() => {
                      this.retryDataQuery("DASHBOARD_WORLD_MAP");
                    }}
                  />
                </div>
                <div className="child-table">
                  <LocationsTable
                    tableData={dataQueries.DASHBOARD_LOCATIONS_TABLE}
                    handleRetry={() => {
                      this.retryDataQuery("DASHBOARD_LOCATIONS_TABLE");
                    }}
                  />
                </div>
              </div>
            </Collapse>
          </div>
          <div className="column-content">
            <Collapse
              collapseId="dashboard-8"
              title="Current Active Findings"
              height="100%"
            >
              <ActiveFindingsTable
                tableData={dataQueries.DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE}
                handleRetry={() => {
                  this.retryDataQuery(
                    "DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE"
                  );
                }}
              />
            </Collapse>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderTrending(secondlevel, data, reports, dataQueries) {
    return (
      <div className="trending">
        <div className="column-header">
          <div className="uppercase">Trending</div>
        </div>
        {["analyst", "responder"].includes(secondlevel)
          ? this.renderAnalystResponderTrending(dataQueries)
          : null}
        {["manager"].includes(secondlevel)
          ? this.renderManagerTrending(data, reports, dataQueries)
          : null}
      </div>
    );
  }

  render() {
    const {
      data = {},
      dataQueries = {},
      loading = false,
      payload = {},
    } = this.props;

    const { secondlevel } = payload;

    const { reports = [] } = data;

    let loadingStyles = {};
    if (loading) {
      loadingStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        marginTop: "50px",
      };
    }

    return (
      <React.Fragment>
        <span className="dashboard" style={{ ...loadingStyles }}>
          {loading && <Loading />}
          {!loading && (
            <div style={{ position: "relative" }}>
              <PageHeader>
                <Link
                  key="MONITORMODE"
                  to={{
                    type: "MONITORMODE",
                    payload: {
                      orgId: payload.orgId,
                    },
                  }}
                >
                  <Button data-cy={"monitorModeBtn"}>Monitor Mode</Button>
                </Link>
              </PageHeader>
              <Scroller
                scrollerStats={
                  dataQueries.DASHBOARD_SCROLLER_STATS.visualizationData
                }
              />
              <div className="dashboard-content">
                {["analyst", "responder", "manager"].includes(secondlevel)
                  ? this.renderTrending(secondlevel, data, reports, dataQueries)
                  : null}
                {/* @TODO: news and activity are potentially getting phased out
                  <div className='col-2 right-column'>
                    { this.renderNews(news, user) }
                    {['manager'].includes(secondlevel) ?
                      this.renderActivityFeed(activity) : null
                    }
                  </div>
                */}
              </div>
            </div>
          )}
        </span>
      </React.Fragment>
    );
  }
}

DashboardPageView.propTypes = {
  orgId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.shape({}),
  user: PropTypes.shape({}),
  payload: PropTypes.shape({}),
  filters: PropTypes.shape({}),
  loading: PropTypes.bool,
  pageTitle: PropTypes.string,
  routeToPage: PropTypes.func.isRequired,
  dataQueries: PropTypes.shape({}),
};

DashboardPageView.defaultProps = {
  user: {},
  payload: {},
  data: {},
  filters: {},
  loading: false,
  pageTitle: "",
  dataQueries: {},
};

const mapDispatchToProps = (dispatch) => ({
  routeToPage: (props) => {
    dispatch({ type: "PAGE", ...props });
  },
});

const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
    payload: state.location.payload,
    findingsStatesPieData: state.dataQuery.dataQueries.DASHBOARD_PIE_CHART,
    dataQueries: state.dataQuery.dataQueries,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageView);

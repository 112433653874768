import React from "react";
import { StyledSVG } from "./styles";

export function MSPIntegrationLogo(props) {
  let svg;
  switch (props.integrationType) {
    case "psa/connectwise":
      svg = (
        <StyledSVG
          width={props.width}
          height={props.height}
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2705.000000 421.000000"
        >
          <g
            transform="translate(0.000000,421.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M309 4150 c-128 -68 -154 -239 -49 -333 42 -39 55 -43 280 -97 1050
          -249 1970 -971 2626 -2062 181 -302 357 -664 459 -948 21 -58 49 -120 62 -138
          55 -75 169 -97 259 -51 46 23 62 45 232 298 l184 272 80 -118 c45 -65 125
          -184 179 -265 54 -80 113 -158 131 -171 115 -88 271 -35 323 109 107 295 287
          680 446 949 558 951 1313 1634 2179 1972 187 74 307 111 505 158 222 53 223
          53 265 95 128 128 37 350 -144 350 -108 0 -491 -105 -745 -204 -414 -162 -752
          -355 -1117 -639 l-109 -85 -60 35 c-121 70 -443 223 -575 271 -366 136 -716
          214 -1095 242 -715 52 -1478 -117 -2115 -470 l-142 -79 -106 85 c-127 101
          -404 287 -556 375 -326 185 -709 337 -1089 429 -181 44 -253 49 -308 20z
          m4406 -790 c426 -48 818 -165 1197 -356 l116 -59 -119 -130 c-421 -456 -792
          -1017 -1045 -1577 l-39 -87 -229 374 c-126 206 -232 372 -237 370 -4 -3 -108
          -169 -231 -370 -122 -201 -225 -365 -228 -365 -3 0 -29 53 -59 118 -248 544
          -630 1115 -1033 1545 -87 93 -108 121 -97 128 37 25 307 152 410 193 306 120
          654 199 1000 226 132 10 464 5 594 -10z"
            />
            <path
              d="M8620 4085 c0 -10 10 -15 30 -15 l30 0 0 -81 c0 -71 2 -80 18 -77 14
          3 17 16 20 81 l3 77 29 0 c20 0 30 5 30 15 0 12 -16 15 -80 15 -64 0 -80 -3
          -80 -15z"
            />
            <path
              d="M8802 4008 c3 -79 6 -93 21 -96 15 -3 17 5 17 55 l0 58 23 -32 c12
          -18 27 -33 32 -33 6 0 20 15 33 33 l22 32 0 -58 c0 -50 2 -58 18 -55 14 3 17
          17 20 96 2 58 -1 92 -7 92 -6 0 -27 -22 -47 -49 l-37 -50 -39 50 c-22 27 -44
          49 -49 49 -6 0 -9 -39 -7 -92z"
            />
            <path
              d="M26675 2883 c-37 -10 -63 -34 -79 -73 -30 -75 15 -148 97 -157 39 -5
          48 -1 80 27 44 38 55 82 34 133 -22 52 -81 83 -132 70z m86 -52 c32 -33 37
          -67 14 -110 -34 -67 -132 -52 -159 23 -10 30 13 85 42 101 41 22 71 18 103
          -14z"
            />
            <path
              d="M26657 2823 c-4 -3 -7 -30 -7 -60 0 -46 3 -53 20 -53 14 0 20 7 21
          23 l0 22 16 -22 c8 -13 24 -23 34 -23 18 0 18 2 4 24 -11 16 -13 26 -6 31 16
          9 13 31 -5 49 -15 16 -65 22 -77 9z m53 -32 c0 -6 -4 -13 -10 -16 -5 -3 -10 1
          -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z"
            />
            <path
              d="M1731 2761 c-584 -531 -1024 -1281 -1220 -2081 -49 -203 -84 -408
          -78 -463 11 -88 70 -155 160 -178 83 -23 178 17 225 93 13 21 30 89 47 183
          152 876 590 1655 1249 2223 l38 33 -23 26 c-28 30 -232 207 -271 235 l-27 19
          -100 -90z"
            />
            <path
              d="M6830 2803 c-30 -25 -101 -86 -158 -136 l-103 -91 63 -55 c646 -566
          1085 -1355 1228 -2208 25 -146 36 -177 76 -216 85 -84 202 -87 289 -6 80 74
          84 128 30 397 -138 686 -416 1287 -843 1825 -98 122 -499 538 -518 536 -5 0
          -34 -21 -64 -46z"
            />
            <path
              d="M10302 2730 c-192 -35 -385 -177 -475 -348 -58 -110 -71 -170 -71
          -312 0 -110 3 -134 28 -205 64 -186 223 -348 403 -410 239 -83 509 -36 681
          120 60 55 122 133 122 154 0 5 -43 40 -96 77 l-97 67 -20 -29 c-41 -58 -111
          -117 -176 -148 -61 -29 -74 -31 -171 -31 -97 0 -110 2 -172 32 -127 60 -208
          170 -227 308 -32 222 83 413 283 470 88 26 212 17 291 -20 56 -27 143 -102
          178 -153 l15 -24 96 68 c53 37 96 71 96 76 0 17 -58 92 -107 140 -109 106
          -259 167 -428 173 -55 2 -124 0 -153 -5z"
            />
            <path
              d="M12005 2734 c-11 -2 -45 -9 -75 -15 -30 -6 -93 -29 -139 -51 -68 -32
          -101 -57 -171 -127 -94 -93 -136 -158 -172 -266 -19 -59 -23 -90 -23 -200 0
          -116 3 -139 28 -208 159 -454 723 -601 1097 -286 224 189 294 525 162 784 -83
          164 -252 303 -427 350 -59 16 -241 28 -280 19z m270 -279 c74 -36 153 -113
          188 -185 53 -108 53 -277 0 -387 -117 -248 -476 -300 -663 -97 -174 189 -129
          524 87 651 75 43 120 53 228 50 85 -3 109 -8 160 -32z"
            />
            <path
              d="M13280 2090 l0 -650 135 0 135 0 2 375 3 375 365 -390 365 -390 42 0
          43 0 0 650 0 650 -130 0 -130 0 -2 -376 -3 -376 -369 391 -368 391 -44 0 -44
          0 0 -650z"
            />
            <path
              d="M14950 2090 l0 -650 130 0 130 0 2 377 3 377 365 -390 366 -389 44 0
          45 0 3 648 2 647 -135 0 -135 0 -2 -374 -3 -373 -365 388 -365 388 -42 0 -43
          1 0 -650z"
            />
            <path
              d="M18440 2726 c-267 -57 -480 -270 -526 -526 -38 -211 18 -411 157
          -564 180 -196 431 -266 699 -195 98 26 181 72 253 138 57 53 117 130 117 149
          0 9 -159 129 -185 138 -6 3 -26 -15 -43 -40 -41 -56 -94 -99 -169 -134 -52
          -24 -70 -27 -163 -27 -97 0 -110 2 -173 32 -82 39 -154 110 -194 190 -26 54
          -28 66 -28 183 0 120 1 128 32 192 40 85 106 151 191 191 63 30 74 32 177 32
          101 0 115 -3 169 -29 57 -28 145 -104 179 -155 l16 -24 95 68 c53 38 96 74 96
          81 0 15 -65 97 -116 146 -136 129 -388 195 -584 154z"
            />
            <path
              d="M21865 2703 c-65 -156 -309 -719 -314 -725 -4 -4 -69 159 -145 362
          l-138 370 -145 0 c-134 0 -145 -1 -141 -17 10 -36 492 -1258 501 -1270 5 -7
          26 -13 47 -13 l38 0 102 228 c56 125 136 304 178 399 42 94 77 171 78 170 1
          -1 81 -180 177 -397 l176 -395 40 -3 c31 -2 42 1 50 17 12 21 490 1236 498
          1264 5 16 -6 17 -140 17 l-145 0 -61 -162 c-34 -90 -97 -254 -139 -365 -41
          -112 -78 -203 -81 -203 -3 0 -44 89 -90 198 -47 108 -121 279 -164 380 l-79
          182 -44 0 c-41 0 -45 -2 -59 -37z"
            />
            <path
              d="M24485 2726 c-194 -44 -308 -172 -309 -346 0 -60 5 -86 25 -130 52
          -112 136 -178 357 -278 199 -90 222 -110 222 -192 0 -53 -43 -103 -105 -120
          -110 -31 -243 16 -335 117 -32 35 -54 52 -62 47 -7 -4 -48 -34 -91 -66 l-77
          -59 16 -30 c26 -50 135 -152 200 -186 85 -45 173 -66 279 -66 193 0 336 77
          407 221 30 60 33 75 33 157 0 73 -5 100 -24 143 -35 78 -121 152 -244 211 -56
          27 -146 70 -200 95 -113 54 -141 85 -133 149 8 69 64 107 158 107 70 0 141
          -34 196 -94 26 -28 48 -44 55 -39 7 4 47 33 89 66 86 66 85 63 26 139 -99 126
          -310 193 -483 154z"
            />
            <path
              d="M16610 2075 l0 -635 440 0 440 0 0 120 0 120 -305 0 -305 0 0 155 0
          155 215 0 215 0 0 115 0 115 -215 0 -215 0 0 125 0 125 295 0 295 0 0 120 0
          120 -430 0 -430 0 0 -635z"
            />
            <path
              d="M19532 2593 l3 -118 203 -3 202 -2 0 -515 0 -515 135 0 135 0 0 515
          0 515 200 0 200 0 0 120 0 120 -540 0 -541 0 3 -117z"
            />
            <path d="M23360 2075 l0 -635 130 0 130 0 0 635 0 635 -130 0 -130 0 0 -635z" />
            <path
              d="M25560 2075 l0 -635 445 0 446 0 -3 118 -3 117 -307 3 -308 2 0 155
          0 155 218 2 217 3 3 113 3 112 -221 0 -220 0 0 125 0 125 295 0 295 0 0 120 0
          120 -430 0 -430 0 0 -635z"
            />
          </g>
        </StyledSVG>
      );
      break;

    default:
      svg = <span />;
      break;
  }
  return svg;
}
